<div class="profile-wrapper">
  <div class="profile-container">
    <input type="file" style="display: none;" (change)="onFileChanged($event)" #fileInput>
    <div class="profile-img" (click)="fileInput.click()" *ngIf="!imageURL && !user.image">
      <img src="../../../assets/Img/LOGO_FOLLOW_LARGO.png" class="logo-fw">
      <div>
        <p>+</p>
      </div>
      <p class="text-14 bold">Subir una imagen</p>
    </div>
    <div class="profile-img" (click)="fileInput.click()" *ngIf="imageURL || user.image">
      <img [src]=imageURL class="profile-img-photo" *ngIf="imageURL">
      <img [src]=user.image class="profile-img-photo" *ngIf="!imageURL && user.image">
      <img src="../../../assets/Img/LOGO_FOLLOW_LARGO.png" class="logo-fw">
    </div>
    <div class="profile-info">
      <img src="/assets/Img/profile-effect.svg" class="profile-effect" (click)="fileInput.click()">
      <span class="inline" style="margin-bottom: 8px;"><input class="mail-profile text-16" type="text" id="userName" name="userName" [(ngModel)]=user.name placeholder="Tu nombre aquí"><i class="fas fa-edit left-space"></i></span>
      <p class="text-14">Haz click para editar estos campos<!--<i class="fas fa-edit left-space"></i>--></p>
      <div class="user-info">
        <div class="inline user-media">
          <i class="fas fa-envelope right-space"></i>
          <div class="input-field"><p class="text-8 bold" style="opacity: .3; letter-spacing: 1px; margin-bottom: 4px;">CORREO</p><input type="text" id="userMail" name="userMail" [(ngModel)]=user.email></div>
          <i class="fas fa-edit left-space"></i>
        </div>
        <div class="inline user-media">
          <i class="fas fa-mobile-alt right-space"></i>
          <div class="input-field"><p class="text-8 bold" style="opacity: .3; letter-spacing: 1px; margin-bottom: 4px;">TELÉFONO</p><input type="text" id="userPhone" name="userPhone" [(ngModel)]=user.phone (keypress)="numberOnly($event)"></div>
          <i class="fas fa-edit left-space"></i>
        </div>

        <div *ngFor="let network of user.networks; let i = index" class="network-container">
          <div class="inline user-media" >
            <img class="media-img" [src]=network.image>
            <div class="input-field">
              <p class="text-8 bold" style="opacity: .3; letter-spacing: 1px; margin-bottom: 4px;">{{network.website | uppercase}}</p>
              <a class="media-text" [href]=network.link target="_blank">{{network.profile}}</a>
            </div>

            <div class="media-icons">
              <i class="fas fa-edit" (click)="toggleEditNetwork(i,network.profile,network.link)"></i>
              <i class="fas fa-times" (click)="deleteNetwork(i)"></i>
            </div>
          </div>

          <ng-container *ngIf="networkToEdit==i">
            <p class="network-title">Editar red social</p>
            <div class="input-field network-field">
              <p class="text-8 bold" style="opacity: .3; letter-spacing: 1px">PERFIL</p>
              <input type="text" name="perfil-red-social" [(ngModel)]=networkToEditProfile placeholder="Ingresa el nuevo nombre">
            </div>
            <div class="input-field network-field">
              <p class="text-8 bold" style="opacity: .3; letter-spacing: 1px">ENLACE</p>
              <input type="text" name="enlace-red-social" [(ngModel)]=networkToEditLink placeholder="Ingresa el nuevo enlace">
            </div>
            <button class="network-btn" (click)="EditNetwork()">Guardar</button>
            <button class="network-btn" (click)="toggleEditNetwork(-1)">Cancelar</button>
          </ng-container>
        </div>
        


        <div class="inline user-media" *ngIf="!showAddNetwork"><img class="media-icon right-space" src="/assets/Img/fw-placeholder.png"><p class="text-14"><span class="text-8 bold" style="opacity: .3; letter-spacing: 1px; margin-bottom: 4px">NUEVO</span><br>Agregar una red social</p><i class="fas fa-plus" (click)="showAddNetwork = !showAddNetwork"></i></div>
        <div class="inline user-media network-add" *ngIf="showAddNetwork">
          <p class="network-title">Agregar red social</p>
          <select name="red-social" class="network-select" [(ngModel)]=selectedNetwork> 
              <option selected disabled value="" hidden>Selecciona una red social</option>
              <option value="Facebook">Facebook</option>
              <option value="Instagram">Instagram</option>
              <option value="Tiktok">Tiktok</option>
              <option value="Snapchat">Snapchat</option>
              <option value="Youtube">Youtube</option>
              <option value="LinkedIn">LinkedIn</option>
              <option value="Twitter">Twitter</option>
              <option value="Custom">Otro</option>
          </select>
          <div class="input-field network-field" *ngIf="selectedNetwork=='Custom'">
            <p class="text-8 bold" style="opacity: .3; letter-spacing: 1px">SITIO</p>
            <input type="text" name="sitio-red-social" [(ngModel)]=network.website placeholder="Nombre del sitio">
          </div>
            <div class="input-field network-field" *ngIf="selectedNetwork!=''">
              <p class="text-8 bold" style="opacity: .3; letter-spacing: 1px">PERFIL</p>
              <input type="text" name="perfil-red-social" [(ngModel)]=network.profile placeholder="Ingresa tu nombre en la plataforma">
            </div>
            <div class="input-field network-field" *ngIf="selectedNetwork!=''">
              <p class="text-8 bold" style="opacity: .3; letter-spacing: 1px">ENLACE</p>
              <input type="text" name="enlace-red-social" [(ngModel)]=network.link placeholder="Ingresa el enlace a tu perfil">
            </div>
            <button class="network-btn" *ngIf="selectedNetwork!=''" (click)="saveNetwork()">Guardar</button>
            <button class="network-btn" *ngIf="showAddNetwork" (click)="showAddNetwork = !showAddNetwork">Cancelar</button>
        </div>
      </div>
    </div>
    <button class="profile-save-btn" (click)="saveUserChanges()">GUARDAR CAMBIOS</button>
    <p class="error-text">{{errorText}}</p>
  </div>
</div>



<!--Modal para editar red social-->