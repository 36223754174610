import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AngularFireModule } from '@angular/fire';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.scss']
})
export class AdminUsersComponent implements OnInit {
  errorText
  users
  usersFiltered

  userQR
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  showQR = false
  userTemp = {
    name: '',
    id: '',
    phone: 0,
    email: ''
  }

  showUsers = false

  userInfo = new FormGroup({
    name: new FormControl('',Validators.required),
    email: new FormControl('',[Validators.required,Validators.email]),
    password: new FormControl('',[Validators.required,Validators.minLength(6)])
  })

  constructor(
    public fireAuth : AngularFireAuth,
    public router: Router,
    public userService: UserService) { }

  ngOnInit(): void {
    this.userService.getUsers().subscribe((res) => {
      this.users = res
      this.usersFiltered = res
    })
  }

  filterList(searchTerm:string){
    this.usersFiltered = this.users.filter(function (el) {
      var name:string = el.name;
      var email:string = el.email
      return name.toLowerCase().includes(searchTerm.toLowerCase()) || email.toLowerCase().includes(searchTerm.toLowerCase())
    });
  }

  createUser(){
    this.fireAuth.createUserWithEmailAndPassword(this.userInfo.controls.email.value, this.userInfo.controls.password.value).then(user => {
      var uid = user.user.uid
      this.userService.createUserRecordAdmin({ id: uid, name: this.userInfo.controls.name.value, email: this.userInfo.controls.email.value}).then(() =>{
        this.userInfo.reset()
      })
    }).catch(err => {
      if (err.code == "auth/invalid-email" || err.code == "auth/wrong-password") {
        this.errorText = "El correo o la contraseña ingresados no son válidos."
      } else if (err.code == "auth/email-already-in-use") {
        this.errorText = "Este correo ya se encuentra en uso."
      } else if (err.code == "auth/weak-password") {
        this.errorText = "La contraseña debe contener un mínimo de 6 caracteres"
      }
    })
  }

  showUsersTable(){
    this.showUsers = true
  }

  hideUsersTable(){
    this.showUsers = false
  }

  showUserQR(user){
    this.showQR = true
    this.userQR = `followears.com/#/ver-perfil/${user.id}`
    this.userTemp = user
  }

  hideUserQR(){
    this.showQR = false
  }

  downloadQRcode() {
    const fileNameToDownload = `${this.userTemp.name}-codigo-qr`;
    const base64Img = document.getElementsByClassName('qrcode')[0].children[0]['src'];
    fetch(base64Img)
      .then(res => res.blob())
      .then((blob) => {
        // IE
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, fileNameToDownload);
        } else { // Chrome
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = fileNameToDownload;
          link.click();
        }
      })
  }

  onLogout(){
    this.fireAuth.signOut().then(() =>{
        this.router.navigate(['login'])
      }
    )
  }

}
