<div class="admin-wrapper">
    <div class="admin-sidebar">
        <div class="admin-logo">
            <img src="../../../assets/Img/fw-logo-short.png">
        </div>
        <div class="admin-tabs">
            <div class="admin-tab" routerLink="/admin/pedidos">
                <p><i class="fas fa-truck"></i> Pedidos</p>
            </div>
            <div class="admin-tab selected-tab" routerLink="/admin/categorias">
                <p><i class="fas fa-tags"></i> Categorias</p>
            </div>
            <div class="admin-tab" routerLink="/admin/productos">
                <p><i class="fas fa-boxes"></i> Productos</p>
            </div>
            <div class="admin-tab" routerLink="/admin/cupones">
                <p><i class="fas fa-ticket-alt"></i> Cupones</p>
            </div>
            <div class="admin-tab" routerLink="/admin/usuarios">
                <p><i class="fas fa-user-friends"></i> Usuarios</p>
            </div>
        </div>
        <div class="log-out-tab" (click)="onLogout()">
            <p><i class="fas fa-sign-out-alt"></i> Cerrar sesión</p>
        </div>
    </div>
    <div class="dashboard-content">
        <div class="categories-header">
            <p class="bold admin-title">Categorías</p>
        </div>
        <div class="categories-body">
            <div class="first-half" [formGroup]="categoryInfo">
                <p class="new-category-title bold">NUEVA CATEGORÍA</p>
                <label for="category-name" class="text-12 bold">Nombre de la categoría</label>
                <input formControlName="categoryName" [class.error-outline]="categoryInfo.get('categoryName').invalid && categoryInfo.get('categoryName').touched" id="category-name" name="category-name" placeholder="Ingresa el nombre de la nueva categoría">
                <p class="error-label" *ngIf="categoryInfo.get('categoryName').invalid && categoryInfo.get('categoryName').touched">Por favor ingresa un nombre</p>
                <button class="add-category-btn" [class.btn-disabled]="categoryInfo.invalid" (click)="createCategory()">AGREGAR CATEGORÍA</button>
            </div>
            <div class="second-half">
                <div class="categories-section">
                    <div class="categories-section-header">
                        <p class="bold">Categorías</p>
                    </div>
                    <div class="categories">
                        <div class="category" *ngFor="let category of categories; let i = index">
                            <div style="display: flex;">
                                <p class="bold">{{category.name}}</p>
                                <div>
                                    <i class="fas fa-edit" (click)="showEditCategoty(i,category.name)"></i>
                                    <i class="fas fa-minus-circle" style="color: red;" (click)="removeCategory(category.id)"></i>
                                </div>
                            </div>
                            <ng-container *ngIf="edit_index==i">
                                <label for="category-edit" class="text-12 bold">NUEVO NOMBRE</label>
                                <input class="category-edit-input" name="category-edit" placeholder="Nombre" [(ngModel)]="edit_name">
                                <button class="edit-category-btn" [class.btn-disabled]="edit_name==''" (click)="saveCategoryChange(category.id)">GUARDAR</button>
                                <button class="edit-category-btn-hide" (click)="hideEditCategory()">CANCELAR</button>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


