<div class="admin-wrapper">
    <div class="admin-sidebar">
        <div class="admin-logo">
            <img src="../../../assets/Img/fw-logo-short.png">
        </div>
        <div class="admin-tabs">
            <div class="admin-tab" routerLink="/admin/pedidos">
                <p><i class="fas fa-truck"></i> Pedidos</p>
            </div>
            <div class="admin-tab" routerLink="/admin/categorias">
                <p><i class="fas fa-tags"></i> Categorias</p>
            </div>
            <div class="admin-tab selected-tab" routerLink="/admin/productos">
                <p><i class="fas fa-boxes"></i> Productos</p>
            </div>
            <div class="admin-tab" routerLink="/admin/cupones">
                <p><i class="fas fa-ticket-alt"></i> Cupones</p>
            </div>
            <div class="admin-tab" routerLink="/admin/usuarios">
                <p><i class="fas fa-user-friends"></i> Usuarios</p>
            </div>
        </div>
        <div class="log-out-tab" (click)="onLogout()">
            <p><i class="fas fa-sign-out-alt"></i> Cerrar sesión</p>
        </div>
    </div>
    <div class="dashboard-content" *ngIf="!showCreateProductForm && !showEditProductForm">
        <div class="products-header">
            <p class="admin-title bold">Productos</p>
            <div class="products-searchbar">
                <i class="fas fa-search"></i>
                <input type=text placeholder="Buscar por ID, nombre o categoría" (keyup)="filterList(search.value)" #search>
            </div>
        </div>
        <div class="products-body">
            <div class="products-btns">
                <button class="new-product-btn" (click)="showCreateProduct()">Nuevo Producto</button>
            </div>
            <div class="products-table">
                <div class="products-table-header">
                    <p class="bold">ID de producto</p>
                    <p class="bold">Nombre</p>
                    <p class="bold">Categoría</p>
                </div>
                <div class="products-table-body">
                    <div class="product" *ngFor="let product of productsFiltered;">
                        <p class="bold">{{product.id}}</p>
                        <p class="bold">{{product.name}}</p>
                        <p class="bold">{{product.category}}</p>
                        <i class="fas fa-edit" (click)="showEditProduct(product)"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="dashboard-content" *ngIf="showCreateProductForm">
        <div class="products-header">
            <p class="admin-title bold">Agregar nuevo producto</p>
        </div>
        <div class="products-btns">
            <button class="new-product-btn" [class.disabled-btn]="productInfo.invalid" (click)="createProduct()">Guardar Producto</button>
            <button class="new-product-btn danger-btn" (click)="hideCreateProduct()">Cancelar</button>
        </div>
        <div class="new-product-body">
            <p class="text-12 bold" style="color: #04FAFC; margin-bottom: 10px; letter-spacing: 1px;">NUEVO PRODUCTO</p>
            <p>Llena todos los campos para añadir un producto a la tienda.</p>
            <div class="new-product-fields" [formGroup]="productInfo">
                <div>
                    <label for="product-name" class="text-12 bold">Nombre del producto</label>
                    <input formControlName="name" [class.error-outline]="productInfo.get('name').invalid && productInfo.get('name').touched" id="product-name" name="product-name" placeholder="Ingresa un nombre">
                    <p class="error-label" *ngIf="productInfo.get('name').invalid && productInfo.get('name').touched">Por favor ingresa un nombre</p>
                    <label for="product-name" class="text-12 bold">Descripción del producto</label>
                    <textarea formControlName="description" [class.error-outline]="productInfo.get('description').invalid && productInfo.get('description').touched" id="product-description" name="product-description" placeholder="Ingresa una descripción"></textarea>
                    <p class="error-label" *ngIf="productInfo.get('description').invalid && productInfo.get('description').touched">Por favor ingresa una descripción</p>
                    <div class="half-flex">
                        <div>
                            <label for="product-price" class="text-12 bold">Precio (MXN)</label>
                            <input formControlName="price" [class.error-outline]="productInfo.get('price').invalid && productInfo.get('price').touched" id="product-price" name="product-price" placeholder="Ingresa un precio">
                            <p class="error-label" *ngIf="productInfo.get('price').invalid && productInfo.get('price').touched">Por favor ingresa un precio</p>
                            <button *ngIf="!showAddColor" class="new-product-btn" style="margin-top: 20px;" (click)="showColor()">Agregar Color</button>
                            <ng-container *ngIf="showAddColor">
                                <label for="product-color" class="text-12 bold">Nombre del color</label>
                                <input id="product-color" name="product-color" placeholder="Ingresa un color" formControlName="colorName">
                                <label for="product-color-code" class="text-12 bold">Código hex del color</label>
                                <input id="product-color-code" name="product-color" placeholder="#FFFFFF" formControlName="colorCode">
                                <button class="new-product-btn" style="margin-top: 20px;" (click)="addColor()">Agregar</button>
                            </ng-container>
                            <div class="colors">
                                <div class="color" [style]='"background-color:"+color.code' *ngFor="let color of colors; let i = index">
                                    <i class="fas fa-minus-circle text-12" style="color: red;" (click)="removeColor(i)"></i>
                                </div>
                            </div>
                        </div>
                        <div>
                            <label for="product-category" class="text-12 bold">Categoría</label>
                            <select name="product-category" formControlName="category" [class.error-outline]="productInfo.get('category').invalid && productInfo.get('category').touched">
                                <option value='' selected disabled>Categoría</option>
                                <option *ngFor="let category of categories;" [value]="category.name">{{category.name}}</option>
                            </select>
                            <p class="error-label" *ngIf="productInfo.get('category').invalid && productInfo.get('category').touched">Por favor selecciona una categoría</p>
                            <button *ngIf="!showAddSize" class="new-product-btn" style="margin-top: 20px;" (click)="showSize()">Agregar Talla</button>
                            <ng-container *ngIf="showAddSize">
                                <label for="product-size" class="text-12 bold">Nueva talla</label>
                                <input id="product-size" name="product-size" placeholder="Ingresa una talla" formControlName="size">
                                <button class="new-product-btn" style="margin-top: 20px;" (click)="addSize()">Agregar</button>
                            </ng-container>
                            <div class="sizes">
                                <div class="size" *ngFor="let size of sizes; let i = index">
                                    {{size}}
                                    <i class="fas fa-minus-circle text-12" style="color: red;" (click)="removeSize(i)"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <label class="text-12 bold">Imágenes del producto</label>
                    <div class="add-image-field" (click)="fileInput.click()">
                        <i class="fas fa-plus"></i>
                    </div>
                    <input type="file" style="display: none;" (change)="onFileChanged($event)" #fileInput>
                    <div class="images">
                        <div class="image" *ngFor="let image of images; let i = index">
                            <img [src]="image">
                            <i class="fas fa-minus-circle text-12" style="color: red;" (click)="removeImage(i)"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="dashboard-content" *ngIf="showEditProductForm">
        <div class="products-header">
            <p class="admin-title bold">Modificar producto</p>
        </div>
        <div class="products-btns">
            <button class="new-product-btn" [class.disabled-btn]="productInfo.invalid" (click)="editProduct(productId)">Guardar Producto</button>
            <button class="new-product-btn danger-btn" (click)="hideEditProduct()">Cancelar</button>
        </div>
        <div class="new-product-body">
            <p class="text-12 bold" style="color: #04FAFC; margin-bottom: 10px; letter-spacing: 1px;">MODIFICAR PRODUCTO</p>
            <p>Llena todos los campos para modificar un producto.</p>
            <div class="new-product-fields" [formGroup]="productInfo">
                <div>
                    <label for="product-name" class="text-12 bold">Nombre del producto</label>
                    <input formControlName="name" [class.error-outline]="productInfo.get('name').invalid && productInfo.get('name').touched" id="product-name" name="product-name" placeholder="Ingresa un nombre">
                    <p class="error-label" *ngIf="productInfo.get('name').invalid && productInfo.get('name').touched">Por favor ingresa un nombre</p>
                    <label for="product-name" class="text-12 bold">Descripción del producto</label>
                    <textarea formControlName="description" [class.error-outline]="productInfo.get('description').invalid && productInfo.get('description').touched" id="product-description" name="product-description" placeholder="Ingresa una descripción"></textarea>
                    <p class="error-label" *ngIf="productInfo.get('description').invalid && productInfo.get('description').touched">Por favor ingresa una descripción</p>
                    <div class="half-flex">
                        <div>
                            <label for="product-price" class="text-12 bold">Precio (MXN)</label>
                            <input formControlName="price" [class.error-outline]="productInfo.get('price').invalid && productInfo.get('price').touched" id="product-price" name="product-price" placeholder="Ingresa un precio">
                            <p class="error-label" *ngIf="productInfo.get('price').invalid && productInfo.get('price').touched">Por favor ingresa un precio</p>
                            <button *ngIf="!showAddColor" class="new-product-btn" style="margin-top: 20px;" (click)="showColor()">Agregar Color</button>
                            <ng-container *ngIf="showAddColor">
                                <label for="product-color" class="text-12 bold">Nombre del color</label>
                                <input id="product-color" name="product-color" placeholder="Ingresa un color" formControlName="colorName">
                                <label for="product-color-code" class="text-12 bold">Código hex del color</label>
                                <input id="product-color-code" name="product-color" placeholder="#FFFFFF" formControlName="colorCode">
                                <button class="new-product-btn" style="margin-top: 20px;" (click)="addColor()">Agregar</button>
                            </ng-container>
                            <div class="colors">
                                <div class="color" [style]='"background-color:"+color.code' *ngFor="let color of colors; let i = index">
                                    <i class="fas fa-minus-circle text-12" style="color: red;" (click)="removeColor(i)"></i>
                                </div>
                            </div>
                        </div>
                        <div>
                            <label for="product-category" class="text-12 bold">Categoría</label>
                            <select name="product-category" formControlName="category" [class.error-outline]="productInfo.get('category').invalid && productInfo.get('category').touched">
                                <option value='' selected disabled>Categoría</option>
                                <option *ngFor="let category of categories;" [value]="category.name">{{category.name}}</option>
                            </select>
                            <p class="error-label" *ngIf="productInfo.get('category').invalid && productInfo.get('category').touched">Por favor selecciona una categoría</p>
                            <button *ngIf="!showAddSize" class="new-product-btn" style="margin-top: 20px;" (click)="showSize()">Agregar Talla</button>
                            <ng-container *ngIf="showAddSize">
                                <label for="product-size" class="text-12 bold">Nueva talla</label>
                                <input id="product-size" name="product-size" placeholder="Ingresa una talla" formControlName="size">
                                <button class="new-product-btn" style="margin-top: 20px;" (click)="addSize()">Agregar</button>
                            </ng-container>
                            <div class="sizes">
                                <div class="size" *ngFor="let size of sizes; let i = index">
                                    {{size}}
                                    <i class="fas fa-minus-circle text-12" style="color: red;" (click)="removeSize(i)"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <label class="text-12 bold">Imágenes del producto</label>
                    <div class="add-image-field" (click)="fileInput.click()">
                        <i class="fas fa-plus"></i>
                    </div>
                    <input type="file" style="display: none;" (change)="onFileChanged($event)" #fileInput>
                    <div class="images">
                        <div class="image" *ngFor="let image of images; let i = index">
                            <img [src]="image">
                            <i class="fas fa-minus-circle text-12" style="color: red;" (click)="removeImage(i)"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
