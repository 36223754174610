<div class="shop-wrapper" *ngIf="!showProduct">
    <img src="../../../assets/Img/shop-triangles.png" class="shop-triangles">
    <img src="../../../assets/Img/shop-blue-dots.png" class="shop-dots">
    <div class="shop-container">
        <div class="search-filters">
            <div>
                <i class="fas fa-search"></i>
                <input type="text" placeholder='Prueba con "Hoodies"' [(ngModel)]="filter_name">
            </div>
                <!-- <select>
                    <option value="volvo" disabled selected>Color</option>
                </select>
                <select>
                    <option value="volvo" disabled selected>Talla</option>
                </select> -->
                <select [(ngModel)]="filter_category">
                    <option value="" disabled selected>Categorías</option>
                    <option value="">Todos</option>
                    <option *ngFor="let category of categories" [value]=category.name>{{category.name}}</option>
                </select>
                <button class="buscar-btn text-16" (click)="filterProducts()">BUSCAR</button>
        </div>
        <div class="product-section">
            <div class="product" [routerLink]="['/producto',product.id]" *ngFor="let product of productsFiltered">
                <div class="product-image">
                    <img [src]=product.images[0]>
                    <p class="text-15">${{product.price}} MXN</p>
                </div>
                <p class="text-16">{{product.name}}</p>
            </div>
        </div>
    </div>
</div>