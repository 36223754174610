import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss']
})
export class AdminDashboardComponent implements OnInit {
  showOrders: boolean = true;
  showProducts: boolean = false;
  showCoupons: boolean = false;
  showCategories: boolean = false;

  constructor(
    public fireAuth : AngularFireAuth,
    private router: Router, 
  ) { }

  ngOnInit(): void {
  }

  displayOrders(){
    this.showOrders = true
    this.showCoupons = false
    this.showProducts = false
    this.showCategories = false
  }

  displayProducts(){
    this.showOrders = false
    this.showCoupons = false
    this.showProducts = true
    this.showCategories = false
  }

  displayCoupons(){
    this.showOrders = false
    this.showCoupons = true
    this.showProducts = false
    this.showCategories = false
  }

  displayCategories(){
    this.showOrders = false
    this.showCoupons = false
    this.showProducts = false
    this.showCategories = true
  }

}
