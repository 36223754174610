<div class="admin-wrapper">
    <div class="admin-sidebar">
        <div class="admin-logo">
            <img src="../../../assets/Img/fw-logo-short.png">
        </div>
        <div class="admin-tabs">
            <div class="admin-tab selected-tab">
                <p><i class="fas fa-truck"></i> Pedidos</p>
            </div>
            <div class="admin-tab" routerLink="/admin/categorias">
                <p><i class="fas fa-tags"></i> Categorias</p>
            </div>
            <div class="admin-tab" routerLink="/admin/productos">
                <p><i class="fas fa-boxes"></i> Productos</p>
            </div>
            <div class="admin-tab" routerLink="/admin/cupones">
                <p><i class="fas fa-ticket-alt"></i> Cupones</p>
            </div>
            <div class="admin-tab" routerLink="/admin/usuarios">
                <p><i class="fas fa-user-friends"></i> Usuarios</p>
            </div>
        </div>
        <div class="log-out-tab" (click)="onLogout()">
            <p><i class="fas fa-sign-out-alt"></i> Cerrar sesión</p>
        </div>
    </div>
    <div class="dashboard-content">
        <div class="orders-header">
            <p class="admin-title bold">Pedidos</p>
            <div class="orders-searchbar">
                <i class="fas fa-search"></i>
                <input type=text placeholder="Buscar usuario, id de orden, fecha o estado" (keyup)="filterList(search.value)" #search>
            </div>
        </div>
        <div class="orders-body">
            <div class="order-header">
                <p class="bold">ID de orden</p>
                <p class="bold">Usuario</p>
                <p class="bold">Estado</p>
                <p class="bold">Fecha</p>
            </div>
            <div class="order" *ngFor="let order of ordersFiltered" (click)="showOrderDetailModal(order)">
                <p class="text-12">{{order.id}}</p>
                <p>{{order.username}}</p>
                <p class="bold" [class.yellow-text]="order.status=='En camino'" [class.green-text]="order.status=='Pagado' || order.status=='Entregado'">{{order.status}}</p>
                <p>{{order.date}}</p>
            </div>
        </div>
        <div class="order-modal" [class.modal-show]="showOrderDetail">
            <div class="order-modal-detail" [class.order-show]="showOrderDetail">
                <i class="fas fa-times" (click)="hideOrderDetailModal()"></i>
                <p class="order-title bold">Resumen de orden</p>
                <div class="row order-subtitle">
                    <div>
                        <p class="text-18"><span class="bold">Estado: </span> <span [class.yellow-text]="orderToShow.status=='En camino'" [class.green-text]="orderToShow.status=='Entregado' || orderToShow.status=='Pagado'">{{orderToShow.status}}</span></p>
                        <p class="text-12 status-btn green-text" (click)="updateOrderStatus('Entregado')" style="margin-top: 15px;" [class.status-selected]="orderToShow.status=='Entregado'">Entregado</p>
                        <p class="text-12 status-btn yellow-text" (click)="updateOrderStatus('En camino')" [class.status-selected]="orderToShow.status=='En camino'">En camino</p>
                        <p class="text-12 status-btn green-text" (click)="updateOrderStatus('Pagado')" [class.status-selected]="orderToShow.status=='Pagado'">Pagado</p>
                    </div>
                    <p class="text-18"><span class="bold">Fecha: </span> {{orderToShow.date}}</p>
                </div>
                <p class="order-detail-title">Resumen de la orden</p>
                <div>
                    <div class="order-detail-product row" *ngFor="let orderProduct of orderToShow.products">
                        <img [src]="orderProduct.image">
                        <p>{{orderProduct.quantity}} x {{orderProduct.name}}</p>
                        <p>{{orderProduct.price | currency}} MXN</p>
                    </div>
                    <div class="subtotal row">
                        <p>Subtotal</p>
                        <p class="bold">{{orderToShow.subtotal | currency}} MXN</p>
                    </div>
                    <div class="shipping row">
                        <p>Envío</p>
                        <p class="bold">{{orderToShow.shippingCost | currency}} MXN</p>
                    </div>
                    <div class="discount row" *ngIf="orderToShow.discount && orderToShow.discount!=0">
                        <p>Descuento</p>
                        <p>{{orderToShow.discountCode | uppercase}}</p>
                        <p class="bold">-{{orderToShow.discount | currency}} MXN</p>
                    </div>
                    <div class="total row">
                        <p class="bold">Total</p>
                        <p class="bold">{{orderToShow.totalCost | currency}} MXN</p>
                    </div>
                </div>
                <p class="order-detail-title">Dirección de entrega</p>
                <div class="order-detail-address">
                    <p class="bold text-18" style="margin-bottom: 15px;">{{orderToShow.username}}</p>
                    <p>{{orderToShow.address}}</p>
                    <p *ngIf="orderToShow.address_2!=''">{{orderToShow.address_2}}</p>
                    <p><span class="bold">C.P.</span> {{orderToShow.postalCode}}</p>
                    <p><span class="bold">Ciudad:</span> {{orderToShow.city}}</p>
                    <p><span class="bold">Estado:</span> {{orderToShow.state}}</p>
                    <p><span class="bold">Correo:</span> {{orderToShow.contactEmail}}</p>
                    <p><span class="bold">Tel.</span> {{orderToShow.contactPhone}}</p>
                    <p *ngIf="orderToShow.extraComments!=''" style="margin-bottom: 15px;"><span class="bold">Indicaciones:</span> {{orderToShow.extraComments}}</p>
                    <ngx-qrcode
                        [elementType]="elementType"
                        [errorCorrectionLevel]="correctionLevel"
                        [value]="value"
                        cssClass="qrcode" class="qr-code"></ngx-qrcode>
                    <p class="download-qr blue-text bold" (click)="downloadQRcode()">Descargar código QR</p>
                </div>
            </div>
        </div>    
    </div>
</div>





<!--<div class="order" *ngFor="let order of orders">
        <div class="order-header">
            <div>
                <p class="text-12 bold blue-title">FECHA</p>
                <p class="text-12">{{order.date}}</p>
            </div>
            <div class="order-total">
                <p class="text-12 bold blue-title">TOTAL</p>
                <p class="text-12">{{order.totalCost | currency}}</p>
            </div>
        </div>
        <div class="order-body">
            <div class="product" *ngFor="let product of order.products">
                <img [src]=product.image>
                <div class="product-text">
                    <p class="text-14 product-name">{{product.quantity}} x {{product.name}}</p>
                    <p class="text-12 product-price bold">{{product.price | currency}}</p>
                </div>
            </div>
        </div>
        <div class="fade-effect-container"></div>
        <a class="blue-text text-12 order-detail">Ver el detalle del pedido</a>
    </div>-->