import { Component, OnInit } from '@angular/core';
import { Params, ActivatedRoute } from '@angular/router';
import { ProductsService } from 'src/app/services/products.service';
import { ShoppingCartService } from 'src/app/services/shopping-cart.service';
import { Product } from 'src/app/shared/product';
import { ShoppingCart } from 'src/app/shared/shoppingCart';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {
  product: any = {id:"", color: [], image: [], name: "", price: 0, size: []};
  selectedImage: string;
  selectedColor: string = '';
  selectedSize: string = '';
  errorText = "";
  cart: ShoppingCart = {userID: "", items: []};

  constructor(public route: ActivatedRoute, public productsService: ProductsService, public shoppingCartService : ShoppingCartService) { }

  ngOnInit(): void {
    let id = this.route.snapshot.params['id'];
    this.productsService.getProductById(id).subscribe((res) => {
      this.product = res;
      this.selectedImage = this.product.images[0]
      this.selectedColor = this.product.colors[0].name
      this.selectedSize = this.product.sizes[0]
    });
    this.shoppingCartService.getTokens().subscribe((res) => {
      this.cart = res;
    })
    if(localStorage.getItem('cart')){
      this.shoppingCartService.setTokens(JSON.parse(localStorage.getItem('cart')));
    }else{
      localStorage.setItem('cart', JSON.stringify({userID: "", items: []}))
      this.shoppingCartService.setTokens({userID: "", items: []});
    }
  }

  selectImage(image){
    this.selectedImage = image;
  }

  selectColor(color){
    this.selectedColor = color;
  }

  selectSize(size: string){
    this.selectedSize = size;
  }

  addToCart(product){
    if(this.selectedColor != null && this.selectedSize != null){
      let tempProduct = {
        id: product.id,
        price: product.price,
        name: product.name+" "+" "+this.selectedColor+" "+this.selectedSize.toUpperCase(),
        quantity: 1,
        image: product.images[0],
        color: this.selectedColor,
        size: this.selectedSize.toUpperCase()
      }
      let productFound = false;
      for(let i of this.cart.items){
        if(i.name === tempProduct.name){
          i.quantity++;
          productFound = true;
        }
      }
      if(!productFound) this.cart.items.push(tempProduct);
      this.shoppingCartService.setTokens(this.cart);
      localStorage.setItem('cart', JSON.stringify(this.cart))
      alert('Producto agregado al carrito')
    }else{
      alert('Por favor selecciona un color y una talla.')
    }
  }
}
