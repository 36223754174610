import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { Upload } from '../../shared/upload'

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  uid;
  value;
  userImage;
  imageURL = null;
  imageUpload;
  errorText:string="";
  showAddNetwork:boolean = false;
  selectedNetwork:string = "";
  networkToEdit:number;
  networkToEditProfile:string="";
  networkToEditLink:string="";
  network = {
    website: "",
    profile: "",
    link: "",
    image: ""
  }

  user = {
    email: "",
    phone: "",
    name: "",
    image: "",
    imageName: "",
    networks: [] 
  }

  constructor(public fireAuth : AngularFireAuth, private router: Router, public userService : UserService, public authService: AuthService) { }

  ngOnInit(): void {
    this.fireAuth.currentUser.then(user =>{
      this.uid = user.uid;
      this.userService.getUserById(this.uid).subscribe((res) => {
        this.user = res;
      })
    })
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if(charCode == 46) return true
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  onFileChanged(event) {
    this.userImage = event.target.files[0];
    if (event.target.files.length === 0)
        return;

    const mimeType = this.userImage.type;
    if (mimeType.match(/image\/*/) == null) {
        this.errorText = "Por favor selecciona una imagen.";
        return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(this.userImage); 
    reader.onload = (_event) => { 
        this.imageURL = reader.result; 
    }
  }

  saveUserChanges(){
    if(this.imageURL){
      if(this.user.image){
        this.userService.deleteUserImage(this.user.imageName);
      }
      this.imageUpload = new Upload(this.userImage);
      this.userService.saveUserChanges(this.user,this.imageUpload,this.uid)
      this.errorText = "Sus cambios han sido guardados."
    }else{
      this.userService.saveUserChanges(this.user,null,this.uid)
      this.errorText = "Sus cambios han sido guardados."
    }
    setTimeout(()=>{
      this.errorText = ""
    },3000)
  }

  saveNetwork(){
    if(this.selectedNetwork!='Custom')this.network.website=this.selectedNetwork
    switch(this.selectedNetwork){
      case 'Facebook':
        this.network.image = "/assets/Networks/facebook-logo.svg"
      break
      case 'Instagram':
        this.network.image = "/assets/Networks/insta-logo.png"
      break
      case 'Tiktok':
        this.network.image = "/assets/Networks/tiktok-logo.svg"
      break
      case 'Snapchat':
        this.network.image = "/assets/Networks/snapchat-logo.png"
      break
      case 'Youtube':
        this.network.image = "/assets/Networks/youtube-logo.png"
      break
      case 'LinkedIn':
        this.network.image = "/assets/Networks/linkedin-logo.png"
      break
      case 'Twitter':
        this.network.image = "/assets/Networks/twitter-logo.png"
      break
      case 'Custom':
        this.network.image = "/assets/Img/fw-placeholder.png"
      break
    }
    let networks = []
    if(this.user.networks)networks = this.user.networks
    networks.push(this.network)
    this.userService.updateNetworks(this.uid,networks)
    this.showAddNetwork = false
    this.network = {
      website: "",
      profile: "",
      link: "",
      image: ""
    }
  }

  toggleEditNetwork(network_number,profile,link){
    this.networkToEdit = network_number
    this.networkToEditProfile = profile
    this.networkToEditLink = link
  }

  deleteNetwork(index){
    let networks = this.user.networks
    networks.splice(index,1)
    this.userService.updateNetworks(this.uid,networks)
  }

  EditNetwork(){
    if(this.networkToEditProfile!="")this.user.networks[this.networkToEdit].profile = this.networkToEditProfile
    if(this.networkToEditLink!="")this.user.networks[this.networkToEdit].link = this.networkToEditLink
    this.userService.updateNetworks(this.uid,this.user.networks)
    this.networkToEdit = -1
  }

  

}
