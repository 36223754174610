import { Component, OnInit } from '@angular/core';
import { ShoppingCartService } from 'src/app/services/shopping-cart.service'
import { ProductsService } from 'src/app/services/products.service'
import { Product } from 'src/app/shared/product';
import { ShoppingCart } from 'src/app/shared/shoppingCart';
import { CategoriesService } from 'src/app/services/categories.service';

@Component({
  selector: 'app-tienda',
  templateUrl: './tienda.component.html',
  styleUrls: ['./tienda.component.scss']
})
export class TiendaComponent implements OnInit {
  showProduct:boolean = false;
  products;
  productsFiltered
  categories
  filter_name:string
  filter_category:string = ''

  constructor(public cartService : ShoppingCartService, public productService : ProductsService, public categoryService : CategoriesService) { }

  ngOnInit(): void {
    this.productService.getProducts().subscribe((res) => {
      this.products = res;
      this.productsFiltered = this.products
    })

    this.categoryService.getCategories().subscribe((res) => {
      this.categories = res
    })

  }

  filterProducts(){
    if(!this.filter_name)this.filter_name = ''
    const search_name = this.filter_name
    const search_category = this.filter_category
    this.productsFiltered = this.products.filter(function (el) {
      var name:string = el.name;
      var category:string = el.category
      return name.toLowerCase().includes(search_name.toLowerCase()) && category.toLowerCase().includes(search_category.toLowerCase())
    })
  }

}
