<div class="account-wrapper">
    <div class="account-container">
        <div>
            <p class="text-20 bold">Mi cuenta</p>
            <p class="log-out-btn blue-text bold" (click)="onLogout()">Cerrar sesión</p>
        </div>
        <div class="details-section">
            <p class="text-20">{{user.email}}</p>
            <ng-container *ngIf="orderList || user.role=='mayoreo'">
                <p class="blue-text text-14" *ngIf="orderList.length>0 || user.role=='mayoreo'" routerLink="/perfil"><i class="fas fa-edit"></i><span>Editar perfil</span></p>
                <p class="blue-text text-14" *ngIf="orderList.length>0 || user.role=='mayoreo'" [routerLink]=this.userUrl><i class="fas fa-share"></i><span>Compartir</span></p>
                <p class="blue-text text-14" *ngIf="orderList.length>0 || user.role=='mayoreo'" (click)="downloadQRcode()"><i class="fas fa-qrcode"></i><span>Descargar Código QR</span></p>
                <ngx-qrcode *ngIf="orderList.length>0 || user.role=='mayoreo'"
                [elementType]="elementType"
                [errorCorrectionLevel]="correctionLevel"
                [value]="value"
                cssClass="bshadow" class="qr-code"></ngx-qrcode>
                <p class="purchase-text text-18" *ngIf="orderList.length<1 && user.role!='mayoreo'">
                    Para visualizar tu código QR debes realizar una compra.
                </p>
            </ng-container>
        </div>
        <div class="payments-wrapper">
            <p class="text-20 bold">Mis métodos de pago</p>
            <div class="payment-method-container" *ngFor="let method of user.paymentMethods">
                <img class="card-image" src="/assets/Networks/visa-logo.png">
                <p class="card-details">{{method.brand | uppercase}} que termina en {{method.last4}}</p>
                <p class="card-expiration">{{method.expirationMonth}} / {{method.expirationYear}}</p>
                <i class="fas fa-trash-alt card-delete" (click)="removeCard(i)"></i>
            </div>
        </div>
        <div class="addresses-wrapper" *ngIf="addresses">
            <p class="text-20 bold">Mis direcciones</p>
            <div class="addresses-container" *ngIf="addresses.length>0">
                <div class="address" *ngFor="let address of addresses; let i = index">
                    <div class="address-header"></div>
                    <div class="address-body">
                        <p class="text-16 address-main">{{address.address}}</p>
                        <p class="text-14 address-secondary">{{address.address_2}}</p>
                        <p class="text-14 address-secondary">{{address.city}}</p>
                        <p class="text-14 address-secondary">{{address.state}}</p>
                        <p class="text-14 address-secondary">C.P. {{address.postalCode}}</p>
                        <p class="text-14 address-secondary">Tel. {{address.contactPhone}}</p>
                        <p class="text-14 address-secondary">Correo: {{address.contactEmail}}</p>
                        <p class="text-14 address-secondary">{{address.extraComments}}</p>
                        <p class="address-options text-12" ><span (click)="showEditAddress(address)">Editar</span> | <span (click)="removeAddress(address.id)">Descartar</span></p>
                    </div>
                </div>
            </div>
        </div>
        <div class="orders-wrapper" *ngIf="orderList">
            <p class="text-20 bold">Mis pedidos</p>
            <div class="orders-container" *ngIf="orderList.length>0">
                <div class="order" *ngFor="let order of orderList">
                    <div class="order-header">
                        <div>
                            <p class="text-12 bold blue-title">FECHA</p>
                            <p class="text-12">{{order.date}}</p>
                        </div>
                        <div class="order-total">
                            <p class="text-12 bold blue-title">TOTAL</p>
                            <p class="text-12">{{order.totalCost | currency}}</p>
                        </div>
                    </div>
                    <div class="order-body">
                        <div class="product" *ngFor="let product of order.products">
                            <img [src]=product.image>
                            <div class="product-text">
                                <p class="text-14 product-name">{{product.quantity}} x {{product.name}}</p>
                                <p class="text-12 product-price bold">{{product.price | currency}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="fade-effect-container"></div>
                    <a class="blue-text text-12 order-detail" (click)="showOrderDetailModal(order)">Ver el detalle del pedido</a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="order-modal" [class.modal-show]="showOrderDetail" (click)="hideOrderDetailModal()">
    <div class="order-modal-detail" [class.order-show]="showOrderDetail">
        <i class="fas fa-times"></i>
        <p class="order-title bold">Resumen de orden</p>
        <div class="row order-subtitle">
            <p class="text-18"><span class="bold">Estado: </span> <span [class.green-text]="orderToShow.status=='Pagado' || orderToShow.status=='Entregado'" [class.yellow-text]="orderToShow.status=='En camino'">{{orderToShow.status}}</span></p>
            <p class="text-18"><span class="bold">Fecha: </span> {{orderToShow.date}}</p>
        </div>
        <p class="order-detail-title">Resumen de la orden</p>
        <div>
            <div class="order-detail-product row" *ngFor="let orderProduct of orderToShow.products">
                <img [src]="orderProduct.image">
                <p>{{orderProduct.quantity}} x {{orderProduct.name}}</p>
                <p>{{orderProduct.price | currency}} MXN</p>
            </div>
            <div class="subtotal row">
                <p>Subtotal</p>
                <p class="bold">{{orderToShow.subtotal | currency}} MXN</p>
            </div>
            <div class="shipping row">
                <p>Envío</p>
                <p class="bold">{{orderToShow.shippingCost | currency}} MXN</p>
            </div>
            <div class="discount row" *ngIf="orderToShow.discount && orderToShow.discount!=0">
                <p>Descuento</p>
                <p>{{orderToShow.discountCode | uppercase}}</p>
                <p class="bold">-{{orderToShow.discount | currency}} MXN</p>
            </div>
            <div class="total row">
                <p class="bold">Total</p>
                <p class="bold">{{orderToShow.totalCost | currency}} MXN</p>
            </div>
        </div>
        <p class="order-detail-title">Dirección de entrega</p>
        <div class="order-detail-address">
            <p>{{orderToShow.address}}</p>
            <p *ngIf="orderToShow.address_2!=''">{{orderToShow.address_2}}</p>
            <p>{{orderToShow.postalCode}}</p>
            <p>{{orderToShow.city}}</p>
            <p>{{orderToShow.state}}</p>
            <p>{{orderToShow.contactEmail}}</p>
            <p>{{orderToShow.contactPhone}}</p>
            <p *ngIf="orderToShow.extraComments!=''">{{orderToShow.extraComments}}</p>
        </div>
    </div>
</div>

<div class="address-modal" [class.modal-show]="showAddressModal">
    <div class="address-modal-edit" [class.edit-show]="showAddressModal">
        <div class="address-modal-header">
            <p>Editar Dirección</p>
            <i class="fas fa-times" (click)="hideEditAddress()"></i>
        </div>
        <form [formGroup]="addressInfo">
            <div class="column-inputs">
                <label for="user-address" class="text-12 bold">Dirección de la calle *</label>
                <input formControlName="address" [class.error-outline]="addressInfo.get('address').invalid && addressInfo.get('address').touched" type="text" id="user-address" placeholder="Número de casa y nombre de la calle">
                <input formControlName="address_2" type="text" id="user-address-2" placeholder="Colonia, Apartamento, habitación, etc. (opcional)">
                <p class="error-label" *ngIf="addressInfo.get('address').invalid && addressInfo.get('address').touched">Por favor ingresa una dirección</p>
            </div>
            <div class="column-inputs">
                <label for="user-city" class="text-12 bold">Ciudad *</label>
                <input formControlName="city" [class.error-outline]="addressInfo.get('city').invalid && addressInfo.get('city').touched" type="text" id="user-city" placeholder="Ciudad dónde entregar">
                <p class="error-label" *ngIf="addressInfo.get('city').invalid && addressInfo.get('city').touched">Por favor ingresa una ciudad válida</p>
            </div>
            <div class="row-inputs">
                <div class="column-inputs" id="state-field">   
                    <label for="user-state" class="text-12 bold">Región / Estado *</label>
                    <select formControlName="state" name="state">
                        <option value="" disabled selected>Seleccione una opción</option>
                        <option value="Aguascalientes">Aguascalientes</option>
                        <option value="Baja California">Baja California</option>
                        <option value="Baja California Sur">Baja California Sur</option>
                        <option value="Campeche">Campeche</option>
                        <option value="Chiapas">Chiapas</option>
                        <option value="Chihuahua">Chihuahua</option>
                        <option value="CDMX">CDMX</option>
                        <option value="Coahuila">Coahuila</option>
                        <option value="Colima">Colima</option>
                        <option value="Durango">Durango</option>
                        <option value="Estado de México">Estado de México</option>
                        <option value="Guanajuato">Guanajuato</option>
                        <option value="Guerrero">Guerrero</option>
                        <option value="Hidalgo">Hidalgo</option>
                        <option value="Jalisco">Jalisco</option>
                        <option value="Michoacán">Michoacán</option>
                        <option value="Morelos">Morelos</option>
                        <option value="Nayarit">Nayarit</option>
                        <option value="Nuevo León">Nuevo León</option>
                        <option value="Oaxaca">Oaxaca</option>
                        <option value="Puebla">Puebla</option>
                        <option value="Querétaro">Querétaro</option>
                        <option value="Quintana Roo">Quintana Roo</option>
                        <option value="San Luis Potosí">San Luis Potosí</option>
                        <option value="Sinaloa">Sinaloa</option>
                        <option value="Sonora">Sonora</option>
                        <option value="Tabasco">Tabasco</option>
                        <option value="Tamaulipas">Tamaulipas</option>
                        <option value="Tlaxcala">Tlaxcala</option>
                        <option value="Veracruz">Veracruz</option>
                        <option value="Yucatán">Yucatán</option>
                        <option value="Zacatecas">Zacatecas</option>
                    </select>
                    <p class="error-label" *ngIf="addressInfo.get('state').invalid && addressInfo.get('state').touched">Seleccione una opción</p>
                </div>
                <div class="column-inputs" id="postal-field">
                    <label for="user-postal" class="text-12 bold">Código postal *</label>
                    <input formControlName="postal" (keypress)="numberOnly($event)" [class.error-outline]="addressInfo.get('postal').invalid && addressInfo.get('postal').touched" type="text" id="user-postal" placeholder="02391">
                    <p class="error-label" *ngIf="addressInfo.get('postal').invalid && addressInfo.get('postal').touched">Código postal inválido</p>
                </div>
            </div>
            <div class="column-inputs">
                <label for="user-phone" class="text-12 bold">Teléfono de contacto *</label>
                <input formControlName="phone" (keypress)="numberOnly($event)" [class.error-outline]="addressInfo.get('phone').invalid && addressInfo.get('phone').touched" type="text" id="user-phone" placeholder="Teléfono de contacto">
                <p class="error-label" *ngIf="addressInfo.get('phone').invalid && addressInfo.get('phone').touched">Por favor ingresa un teléfono válido</p>
            </div>
            <div class="column-inputs">
                <label for="user-email" class="text-12 bold">Correo electrónico de contacto *</label>
                <input formControlName="email" [class.error-outline]="addressInfo.get('email').invalid && addressInfo.get('email').touched" type="text" id="user-email" placeholder="Correo de contacto">
                <p class="error-label" *ngIf="addressInfo.get('email').invalid && addressInfo.get('email').touched">Por favor ingresa un correo válido</p>
            </div>
            <div class="column-inputs">
                <label for="user-extra-info" class="text-12 bold">Indicaciones Extra</label>
                <textarea formControlName="extraComments" id="user-extra-info" placeholder="Dejar con el policia en caso de no estar,"></textarea>
            </div>
            <button class="save-address-btn" (click)="saveAddressChanges()" [class.btn-disabled]="addressInfo.invalid">GUARDAR CAMBIOS</button>
        </form>
    </div>
</div>
