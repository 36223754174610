import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {
  private categoryList;
  private categoryCollection: AngularFirestoreCollection

  constructor(private firestore: AngularFirestore) {
    this.categoryCollection = this.firestore.collection('categories');
    this.categoryList = this.categoryCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
  }

  addCategory(category){
    return this.categoryCollection.add(category)
  }

  updateCategory(id, category){
    return this.categoryCollection.doc(id).update(category);
  }

  getCategories(){
    return this.categoryList;
  }

  removeCategory(id){
    return this.categoryCollection.doc(id).delete();
  }

}
