import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {
  private orderList;
  private orderCollection: AngularFirestoreCollection

  constructor(private firestore: AngularFirestore) {
    this.orderCollection = this.firestore.collection('orders');
    this.orderList = this.orderCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
  }

  addOrder(order){
    return this.orderCollection.add(order)
  }

  updateOrder(id, order){
    return this.orderCollection.doc(id).update(order);
  }

  getOrders(){
    return this.orderList;
  }

  getOrderById(id){
    return this.orderCollection.doc(id).valueChanges();
  }

  getOrdersByUserId(uid){
    let orderCollection = this.firestore.collection<any>('orders', ref => ref.where('uid','==',uid));
    let orderList = orderCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    )
    return orderList
  }

  removeOrder(id){
    return this.orderCollection.doc(id).delete();
  }

}
