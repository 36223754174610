import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import * as firebase from 'firebase'

@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  private productList
  private productCollection: AngularFirestoreCollection
  newImages = []

  constructor(private firestore: AngularFirestore) {
    this.productCollection = this.firestore.collection('products');
    this.productList = this.productCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
  }

  uploadImage(image,name){
    const storageRef = firebase.default.storage().ref();
    return new Promise((resolve,reject) => {
      let uploadTask = storageRef.child(`products/${name}-${Date.now()}`).put(image.file)
      uploadTask.on(firebase.default.storage.TaskEvent.STATE_CHANGED,
        (snapshot) =>{
          image.progress = (snapshot.bytesTransferred/snapshot.totalBytes)*100
        },
        (error) => {
          console.log(error)
          reject(error)
        },
        ()=>{
           uploadTask.snapshot.ref.getDownloadURL().then(url =>{
            image.url = url
            image.name = image.file.name
            this.newImages.push(url)
            resolve(url)
        })
      })
    })
  }

  addProduct(product){
    if(!product.images)return this.productCollection.add(product)
      let storageRef = firebase.default.storage().ref()
      let uploadTask
      
      Promise.all(
        product.images.map(async image => {
          return await this.uploadImage(image,product.name)
        })
      ).then(() => {
        product.images = this.newImages
        this.productCollection.add(product)
        this.newImages = []
        return this.productCollection.add(product)
      })
    
  }

  updateProduct(id, product, imagesToUpload){
    if(!imagesToUpload)return this.productCollection.doc(id).update(product)

    
    Promise.all(
      imagesToUpload.map(async image => {
        return await this.uploadImage(image,product.name)
      })
    ).then(() => {
      product.images = product.images.concat(this.newImages)
      this.newImages = []
      return this.productCollection.doc(id).update(product)
    });
  }

  deleteImageFromFirestore(url){
    url = url.replace('https://firebasestorage.googleapis.com/v0/b/followwears-a61a2.appspot.com/o/products%2F', '')
    let toReplace = url.indexOf('?')
    url = url.substring(0,toReplace)
    let storageRef = firebase.default.storage().ref()
    return storageRef.child(`products/${url}`).delete()
  }

  getProducts(){
    return this.productList;
  }

  getProductById(id){
    return this.productCollection.doc(id).valueChanges();
  }

  removeProduct(id){
    return this.productCollection.doc(id).delete();
  }

}
