import { Component, OnInit } from '@angular/core';
import { OrdersService } from 'src/app/services/orders.service';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'orders',
  templateUrl: './admin-orders.component.html',
  styleUrls: ['./admin-orders.component.scss']
})
export class AdminOrdersComponent implements OnInit {
  orders
  ordersFiltered
  showOrderDetail
  orderToShow = {
    date: "",
    address: "",
    address_2: "",
    city: "",
    state: "",
    postalCode: "",
    contactPhone: "",
    contactEmail: "",
    extraComments: "",
    discountCode: '',
    products: [],
    totalCost: 0,
    discount: 0,
    subtotal: 0,
    shippingCost: 0,
    status: '',
    id: '',
    username: ''
  }
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  value

  constructor(
    public orderService: OrdersService,
    public fireAuth : AngularFireAuth,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.orderService.getOrders().subscribe((res) => {
      for(let order of res){
        order.date = this.transformDate(order.date)
      }
      this.orders = res.sort(this.compare);
      this.ordersFiltered = this.orders
    })
  }

  filterList(searchTerm:string){
    this.ordersFiltered = this.orders.filter(function (el) {
      var name:string = el.username;
      var id:string = el.id
      var status:string = el.status
      var date = el.date
      return name.toLowerCase().includes(searchTerm.toLowerCase()) || status.toLowerCase().includes(searchTerm.toLowerCase()) || date.toLowerCase().includes(searchTerm.toLowerCase()) || id.toLowerCase().includes(searchTerm.toLowerCase())
    });
  }

  compare(a,b){
    if(a.date > b.date)return -1
    return 1
  }

  transformDate(date) {
    let d;
    if (date != undefined) d = new Date(date.seconds * 1000);
    if (date == undefined) d = new Date();
    let options = { day: "numeric", month: "long", year: "numeric" };
    return d.toLocaleDateString("es-ES", options);
  }

  showOrderDetailModal(order) {
    this.showOrderDetail = true
    this.orderToShow = order
    this.value = `followears.com/#/ver-perfil/${order.uid}`
  }

  hideOrderDetailModal() {
    this.showOrderDetail = false
  }

  updateOrderStatus(status:string){
    this.orderService.updateOrder(this.orderToShow.id,{status:status})
    this.orderToShow.status = status
  }

  downloadQRcode() {
    const fileNameToDownload = `${this.orderToShow.username}-codigo-qr`;
    const base64Img = document.getElementsByClassName('qrcode')[0].children[0]['src'];
    fetch(base64Img)
      .then(res => res.blob())
      .then((blob) => {
        // IE
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, fileNameToDownload);
        } else { // Chrome
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = fileNameToDownload;
          link.click();
        }
      })
  }

  onLogout(){
    this.fireAuth.signOut().then(() =>{
        this.router.navigate(['login'])
      }
    )
  }

}
