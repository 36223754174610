<div class="signup-wrapper">
    <div class="signup-container">
        <img src="../../../assets/Img/fw-logo-short.png" id="fw-logo-short">
        <div class="input-text">
            <label class="text-10 bold">NOMBRE</label><input type="text" placeholder="Nombre" [(ngModel)]="user.name">
        </div>
        <div class="input-text">
            <label class="text-10 bold">CORREO</label><input type="text" placeholder="Correo" [(ngModel)]="user.mail">
        </div>
        <div class="input-text">
            <label class="text-10 bold">CONTRASEÑA</label><input type="password" placeholder="Contraseña" [(ngModel)]="user.password">
        </div>
        <p class="error-text">{{errorText}}</p>
        <button (click)="signup()" class="signup-btn text-16 bold">INICIAR SESIÓN</button>
        <a class="password-restore-link" routerLink="/login">Ya tengo cuenta</a>
        <p class="rights-text">Follow Wears. Todos los derechos reservados. 2021</p>
    </div>
</div>
<!--<button (click)="createUser(mail.value,username.value,password.value)">Crear cuenta</button>
<button style="background-color: rgba(255,61,45,1);" (click)="SignupWithGoogle()"><i
        class="fab fa-google"></i>Google</button>
<button style="background-color: rgba(27,72,171,1);" (click)="SignUpWithFacebook()"><i
        class="fab fa-facebook-f"></i>Facebook</button>
<p>{{errorText}}</p>-->