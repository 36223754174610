// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyBZWwRPCh0YA4Coy-bN3PA4r4ImyOTi2Zo",
    authDomain: "followwears-a61a2.firebaseapp.com",
    projectId: "followwears-a61a2",
    storageBucket: "followwears-a61a2.appspot.com",
    messagingSenderId: "163401502526",
    appId: "1:163401502526:web:3fcb53e96b2fc3e50d666f",
    measurementId: "G-T24PSR9BFS"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
