import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  errorText
  passwordReset = false;
  email = ''
  password = ''
  emailRestore = ''

  constructor(public fireAuth : AngularFireAuth, private router: Router, public authService: AuthService, public userService: UserService) { }

  ngOnInit(): void {
    this.fireAuth.currentUser.then(user =>{
      if(user){
        this.userService.getUserById(user.uid).subscribe(res => {
          if(res.role == 'user'){
            this.router.navigate(['/micuenta'])
          }else{
            this.router.navigate(['/admin/'])
          }
        })
      }
    })
  }

  Login(){
    this.fireAuth.signInWithEmailAndPassword(this.email,this.password).then(user => {
      var uid = user.user.uid
      this.userService.getUserRecord(uid).then(data => {
        if(data.role=='admin'){
          this.router.navigate(['/admin'])
        }else {
          this.router.navigate(['/micuenta'])
        }
      })
    }).catch(err => {
      if(err.code == "auth/invalid-email" || err.code == "auth/wrong-password"){
        this.errorText = "El correo o la contraseña ingresados no son válidos."
      }
      console.log(err)
    })
  }

  showPasswordReset(){
    this.passwordReset= true;
  }

  hidePasswordReset(){
    this.passwordReset= false;
  }

  resetPassword(){
    this.fireAuth.sendPasswordResetEmail(this.emailRestore).then(() => {
      alert('Se ha enviado un correo de restablecimiento a tu cuenta.')
      this.passwordReset = false
    },err => {
      if(err.code == "auth/invalid-email"){
        this.errorText = "El correo o la contraseña ingresados no son válidos."
      }else if(err.code == "auth/user-not-found"){
        this.errorText = "No se encontró un usuario con ese correo."
      }
    })
  }

}
