import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ShoppingCartService } from 'src/app/services/shopping-cart.service';
import { ShoppingCart } from 'src/app/shared/shoppingCart';
import * as firebase from "firebase";
import { AngularFireFunctions } from '@angular/fire/functions';
import { UserService } from 'src/app/services/user.service';
import { AuthService } from 'src/app/services/auth.service';
import { OrdersService } from 'src/app/services/orders.service';
import { AddressService } from 'src/app/services/address.service';
import { FormGroup, FormControl } from '@angular/forms';
import { Validators } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { CouponsService } from 'src/app/services/coupons.service';

declare let paypal;

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  currentUser;
  productNumber = 0;
  shippingCost = 150; //Aquí es donde me la pelas
  totalCost = 0;
  discount = 0;
  currentYear: number = new Date().getFullYear();
  availableYears: number[] = [];
  cart: ShoppingCart;
  user;
  userID;
  userMail = "erickgj14@hotmail.com";
  usersWMail;
  addresses
  mailInUse = false;
  couponError = ''
  coupons

  shippingInfo = new FormGroup({
    chosenAddress: new FormControl(''),
    address: new FormControl('Arquímedes #172 Col. Polanco', Validators.required),
    address_2: new FormControl(''),
    city: new FormControl('CDMX', [
      Validators.required,
      Validators.min(3)
    ]),
    state: new FormControl('CDMX', Validators.required),
    postal: new FormControl('11550', [
      Validators.required,
      Validators.pattern("^[0-9]+$")
    ]),
    phone: new FormControl('5543692139', [
      Validators.required,
      Validators.pattern("^[0-9]+$"),
      Validators.minLength(8),
      Validators.maxLength(10)
    ]),
    email: new FormControl('erickgj14@hotmail.com', [
      Validators.required,
      Validators.email
    ]),
    extraComments: new FormControl('')
  })

  cardInfo = new FormGroup({
    chosenCard: new FormControl(''),
    cardHolder: new FormControl('Json', [
      Validators.required
    ]),
    //4242424242424242
    //4000000000000002
    cardNumber: new FormControl('4242424242424242', [
      Validators.required,
      Validators.pattern("^[0-9]+$"),
      Validators.maxLength(16)
    ]),
    cardMonth: new FormControl('12', [
      Validators.required
    ]),
    cardYear: new FormControl(2024,[
      Validators.required
    ]),
    cardCvc: new FormControl('546',[
      Validators.required,
      Validators.pattern("^[0-9]+$"),
      Validators.minLength(3),
      Validators.maxLength(4)
    ])

  })

  constructor(public fireAuth : AngularFireAuth,
    public router: Router, 
    public shoppingCartService: ShoppingCartService, 
    private fns: AngularFireFunctions, 
    public userService: UserService, 
    public authService: AuthService,
    public orderService: OrdersService,
    public addressService: AddressService,
    public spinner: NgxSpinnerService,
    public couponsService: CouponsService) { }


  @ViewChild("paypal", { static: true }) paypalElement: ElementRef;
  ngOnInit(): void {
    this.authService.getCurrentUser().then((res) => {
      if (res) {
        this.userID = res.uid;
        this.userService.getUserById(res.uid).subscribe((res) => {
          this.user = res;
        })
        this.addressService.getAddresssByUserId(res.uid).subscribe((res) => {
          this.addresses = res;
        })
      }
    })

    this.couponsService.getCoupons().subscribe((res) => {
      this.coupons = res
    })

    for (let i = 0; i < 15; i++) {
      this.availableYears.push(this.currentYear + i)
    }

    this.shoppingCartService.getTokens().subscribe((res) => {
      this.productNumber = 0
      this.totalCost = 0
      this.cart = res;
      for (let i of res.items) {
        this.productNumber += i.quantity;
        this.totalCost += i.price * i.quantity;
      }
    })

    if (localStorage.getItem('cart')) {
      this.shoppingCartService.setTokens(JSON.parse(localStorage.getItem('cart')));
    } else {
      localStorage.setItem('cart', JSON.stringify({ userID: "", items: [] }))
      this.shoppingCartService.setTokens({ userID: "", items: [] });
    }

    
    paypal.Buttons({
      style: {
        layout: 'horizontal'
      },
      createOrder: (data, actions) => {
        // This function sets up the details of the transaction, including the amount and line item details.
        return actions.order.create({
          purchase_units: [{
            amount: {
              value: (this.totalCost + this.shippingCost - this.discount),
              currency_code: "MXN"
            }
          }]
        });
      },
      onApprove: (data, actions) => {
              if(this.shippingInfo.get('chosenAddress').value==''){
                this.saveAddress();
                this.createOrderWithNewAddress();
              }else{
                this.createOrderWithExistingAddress();
              }
              //this.router.navigate(['/micuenta'])
        return actions.order.capture().then((details) => {
        });
      }
    }).render(this.paypalElement.nativeElement);
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode == 46) return true
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  removeFromCart(index) {
    this.cart.items.splice(index, 1);
    this.shoppingCartService.setTokens(this.cart);
    localStorage.setItem('cart', JSON.stringify(this.cart))
  }

  clearCart(){
    localStorage.setItem('cart', JSON.stringify({ userID: "", items: [] }))
    this.shoppingCartService.setTokens({ userID: "", items: [] });
  }

  updateCartAmount(index, value) {
    this.cart.items[index].quantity = parseInt(value)
    this.shoppingCartService.setTokens(this.cart);
    localStorage.setItem('cart', JSON.stringify(this.cart))
  }

  onChangeMail() {
    this.mailInUse = false;
  }

  createUser() {
    var mailIsUsed;
    var payCard;
        this.payCart();
        this.mailInUse = true;
  }

  applyCoupon(code){
    let tempCoupon = this.coupons.find(x => x.code == code)
    console.log(tempCoupon)
    if(tempCoupon) this.discount = tempCoupon.discount
    if(!tempCoupon) this.couponError = 'Cupón inválido'
  }

  payCart() {
    this.spinner.show();
    let payment_methods = []
    let card_id
    let card_details
    var payAmount = Math.round(((this.totalCost + this.shippingCost - this.discount) * 100));
    const pay = this.fns.httpsCallable("pay");
    if(this.cardInfo.get('chosenCard').value!=''){
      let chosenCard = this.user.paymentMethods[this.cardInfo.get('chosenCard').value]
      pay({id: this.user.stripe_id, amount: payAmount, card_id: chosenCard.stripe_id }).subscribe((res) => {
        if(res.success){
          //Si es una nueva dirección, guardarla en la base de datos
          if(this.shippingInfo.get('chosenAddress').value==''){
            this.saveAddress();
            this.createOrderWithNewAddress();
          }else{
            this.createOrderWithExistingAddress();
          }
          this.clearCart()
          this.spinner.hide(); 
          alert("El pago se ha realizado con éxito")
          this.router.navigate(['/micuenta'])
        }else{
          console.log(res)
          alert("Error al pagar")
          this.spinner.hide();
        }
      }, error => {
        console.log(error)
      })
      return
    }
    const newPaymentMethod = this.fns.httpsCallable("newPaymentMethod");
    newPaymentMethod({ card: this.cardInfo.value, stripe_id: this.user.stripe_id }).subscribe((res) => {
      card_id = res.stripe_id
      card_details = res
      pay({ id: this.user.stripe_id, amount: payAmount, card_id: card_id }).subscribe((res) => {
          if(res.success){
            if(this.user.paymentMethods)payment_methods = this.user.paymentMethods
            if(card_details.brand=="visa")card_details.image="/assets/Networks/visa-logo.png"
            if(card_details.brand=="mastercard")card_details.image="/assets/Networks/mastercard-logo.png"
            card_details.expirationYear = this.cardInfo.get('cardYear').value
            card_details.expirationMonth = this.cardInfo.get('cardMonth').value
            payment_methods.push(card_details)
            this.userService.updatePaymentMethods(this.userID,payment_methods)
            //Si es una nueva dirección, guardarla en la base de datos
            if(this.shippingInfo.get('chosenAddress').value==''){
              this.saveAddress();
              this.createOrderWithNewAddress();
            }else{
              this.createOrderWithExistingAddress();
            }
            //this.clearCart()
            this.spinner.hide();
            alert("El pago se ha realizado con éxito")
            this.router.navigate(['/micuenta'])
          }else{
            alert("Error al pagar")
            this.spinner.hide();
          }
      })
    }, error => {
      console.log(error)
    })
  }


  async createOrderWithNewAddress(){
    let userID;
      userID = this.userID
      const userName = this.user.name;
      this.orderService.addOrder({
        uid: userID,
        address: this.shippingInfo.get('address').value,
        address_2: this.shippingInfo.get('address_2').value,
        city: this.shippingInfo.get('city').value,
        state: this.shippingInfo.get('state').value,
        postalCode: this.shippingInfo.get('postal').value,
        contactPhone: this.shippingInfo.get('phone').value,
        contactEmail: this.shippingInfo.get('email').value,
        extraComments: this.shippingInfo.get('extraComments').value,
        products: this.cart.items,
        shippingCost: this.shippingCost,
        subtotal: this.totalCost,
        discount: this.discount,
        discountCode: '',
        status: 'Pagado',
        username: userName,
        totalCost: this.totalCost + this.shippingCost - this.discount,
        date: new Date()
      })
  }

  async createOrderWithExistingAddress(){
    let address = this.addresses[this.shippingInfo.get('chosenAddress').value]
    const userName = this.user.name;
    this.orderService.addOrder({
      uid: this.userID,
      address: address.address,
      address_2: address.address_2,
      city: address.city,
      state: address.state,
      postalCode: address.postalCode,
      contactPhone: address.contactPhone,
      contactEmail: address.contactEmail,
      extraComments: address.extraComments,
      shippingCost: this.shippingCost,
      subtotal: this.totalCost,
      discount: this.discount,
      discountCode: '',
      status: 'Pagado',
      username: userName,
      products: this.cart.items,
      totalCost: this.totalCost + this.shippingCost - this.discount,
      date: new Date()
    })
  }

  saveAddress(){
    this.authService.getCurrentUser().then((res) => {
      this.addressService.addAddress({
      uid: res.uid,
      address: this.shippingInfo.get('address').value,
      address_2: this.shippingInfo.get('address_2').value,
      city: this.shippingInfo.get('city').value,
      state: this.shippingInfo.get('state').value,
      postalCode: this.shippingInfo.get('postal').value,
      contactPhone: this.shippingInfo.get('phone').value,
      contactEmail: this.shippingInfo.get('email').value,
      extraComments: this.shippingInfo.get('extraComments').value,
      })
    })
    
  }

}
