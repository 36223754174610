<div class="carrito-wrapper">
    <div class="carrito-container">
        <div class="carrito-header">
            <p class="carrito-title">Mi carrito de compras</p>
            <p class="carrito-price">$7182.00 mxn</p>
        </div>
        <div class="carrito-discount">
            <label for="discount">¿Tienes un código de descuento?</label>
            <input type="text" placeholder="WRZNE100" class="carrito-discount" id="discount">
            <button class="carrito-discount-btn">APLICAR</button>
        </div>
        <div class="carrito-product-list">
            <div class="carrito-product">
                <img class="product-img">
                <p class="product-details text-16"><span class="bold text-18">Producto con un nombre interesante...</span><br>XS, Blanco</p>
                <div></div>
            </div>
        </div>
    </div>
</div>

