import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { LoginComponent } from './components/login/login.component';
import { ProfileComponent } from './components/profile/profile.component';
import { ProfileViewComponent } from './components/profile-view/profile-view.component';
import { SignupComponent } from './components/signup/signup.component';
import { AngularFireAuthGuard, customClaims, redirectUnauthorizedTo} from '@angular/fire/auth-guard'
import { TiendaComponent } from './components/tienda/tienda.component';
import { LandingPageMayoreoComponent } from './components/landing-page-mayoreo/landing-page-mayoreo.component'
import { AccountComponent } from './components/account/account.component';
import { ProductComponent } from './components/product/product.component';
import { PaymentComponent } from './components/payment/payment.component';
import { AdminDashboardComponent } from './components/admin-dashboard/admin-dashboard.component';
import { AuthGuard } from './services/auth-guard.service'
import { AdminOrdersComponent } from './components/admin-orders/admin-orders.component';
import { AdminCategoriesComponent } from './components/admin-categories/admin-categories.component';
import { AdminProductsComponent } from './components/admin-products/admin-products.component';
import { AdminCouponsComponent } from './components/admin-coupons/admin-coupons.component';
import { AdminUsersComponent } from './components/admin-users/admin-users.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { TermsComponent } from './components/terms/terms.component';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login'])

const routes: Routes = [
  {path: 'inicio', component: LandingPageComponent},
  {path: 'aviso', component: PrivacyComponent},
  {path: 'terminos', component: TermsComponent},
  {path: 'login', component: LoginComponent},
  {path: 'signup', component: SignupComponent},
  {path: 'tienda', component: TiendaComponent},
  {path: 'perfil', component: ProfileComponent, canActivate: [AngularFireAuthGuard], data: {authGuardPipe: redirectUnauthorizedToLogin}},
  {path: 'ver-perfil/:id', component: ProfileViewComponent},
  {path: 'mayoreo', component: LandingPageMayoreoComponent},
  {path: 'micuenta', component: AccountComponent, data: {authGuardPipe: redirectUnauthorizedToLogin}},
  {path: 'producto/:id', component: ProductComponent},
  {path: 'pago', component: PaymentComponent, data: {authGuardPipe: redirectUnauthorizedToLogin}},
  {path: 'admin/pedidos', component: AdminOrdersComponent, canActivate: [AuthGuard]},
  {path: 'admin/categorias', component: AdminCategoriesComponent, canActivate: [AuthGuard]},
  {path: 'admin/productos', component: AdminProductsComponent, canActivate: [AuthGuard]},
  {path: 'admin/cupones', component: AdminCouponsComponent, canActivate: [AuthGuard]},
  {path: 'admin/usuarios', component: AdminUsersComponent, canActivate: [AuthGuard]},
  {path: 'admin', redirectTo: '/admin/pedidos', pathMatch: 'full' , canActivate: [AuthGuard]},
  {path: '', redirectTo: '/inicio', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,  { useHash: true, scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
