<div class="profile-wrapper">
    <div class="profile-container">
      <div class="profile-img" *ngIf="user.image">
        <img [src]=user.image class="profile-img-photo" *ngIf="user.image">
        <img src="../../../assets/Img/LOGO_FOLLOW_LARGO.png" class="logo-fw">
      </div>
      <div class="profile-img" *ngIf="!user.image">
        <div>
          <p>X</p>
        </div>
        <p class="text-14 bold">No hay imagen disponible</p>
        <img src="../../../assets/Img/LOGO_FOLLOW_LARGO.png" class="logo-fw">
      </div>
      <div class="profile-info">
        <img src="/assets/Img/profile-effect.svg" class="profile-effect">
        <span class="inline" style="margin-bottom: 5px;"><p class="mail-profile text-20">{{user.name}}</p></span>
        <div class="user-info">
          <div class="inline user-media"><i class="fas fa-envelope right-space"></i><p class="text-14"><span class="text-8 bold" style="opacity: .3; letter-spacing: 1px; margin-bottom: 8px;">CORREO</span><br>{{user.email}}</p></div>
          <div class="inline user-media"><i class="fas fa-mobile-alt right-space"></i><p class="text-14"><span class="text-8 bold" style="opacity: .3; letter-spacing: 1px;">TELEFONO</span><br>{{user.phone}}</p></div>
          <div class="inline user-media" *ngFor="let network of user.networks">
            <img class="media-img" [src]=network.image>
            <div class="input-field">
              <p class="text-8 bold" style="opacity: .3; letter-spacing: 1px; margin-bottom: 4px;">{{network.website | uppercase}}</p>
              <a class="media-text" [href]=network.link target="_blank">{{network.profile}}</a>
            </div>
          </div>
          <!--<div class="inline user-media"><div class="media-icon right-space"></div><p class="text-14"><span class="text-8 bold" style="opacity: .3; letter-spacing: 1px;">NUEVO</span><br>Selecciona uno</p></div>-->
        </div>
      </div>
    </div>
  </div>
  