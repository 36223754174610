<div class="admin-wrapper">
    <div class="admin-sidebar">
        <div class="admin-logo">
            <img src="../../../assets/Img/fw-logo-short.png">
        </div>
        <div class="admin-tabs">
            <div class="admin-tab" routerLink="/admin/pedidos">
                <p><i class="fas fa-truck"></i> Pedidos</p>
            </div>
            <div class="admin-tab" routerLink="/admin/categorias">
                <p><i class="fas fa-tags"></i> Categorias</p>
            </div>
            <div class="admin-tab" routerLink="/admin/productos">
                <p><i class="fas fa-boxes"></i> Productos</p>
            </div>
            <div class="admin-tab" routerLink="/admin/cupones">
                <p><i class="fas fa-ticket-alt"></i> Cupones</p>
            </div>
            <div class="admin-tab selected-tab" routerLink="/admin/usuarios">
                <p><i class="fas fa-user-friends"></i> Usuarios</p>
            </div>
        </div>
        <div class="log-out-tab" (click)="onLogout()">
            <p><i class="fas fa-sign-out-alt"></i> Cerrar sesión</p>
        </div>
    </div>
    <div class="dashboard-content" *ngIf="!showUsers">
           <div class="admin-users-header">
                <p class="admin-title bold">Usuarios</p>
           </div>
           <div class="admin-users-content">
            <div class="form-fields" [formGroup]="userInfo">
                <p class="new-coupon-title bold">NUEVO USUARIO</p>
                <p>Llena todos los campos para agregar un usuario de mayoreo</p>
                <label for="user-name" class="text-12 bold">Nombre de usuario</label>
                <input formControlName="name" [class.error-outline]="userInfo.get('name').invalid && userInfo.get('name').touched" id="user-name" name="category-name" placeholder="Ingresa un nombre">
                <p class="error-label" *ngIf="userInfo.get('name').invalid && userInfo.get('name').touched">Por favor ingresa un nombre</p>
                <label for="user-email" class="text-12 bold">Correo del usuario</label>
                <input formControlName="email" [class.error-outline]="userInfo.get('email').invalid && userInfo.get('email').touched" id="coupon-email" name="category-email" placeholder="Ingresa un correo">
                <p class="error-label" *ngIf="userInfo.get('email').invalid && userInfo.get('email').touched">Por favor ingresa un correo</p>
                <label for="user-password" class="text-12 bold">Contraseña del usuario</label>
                <input formControlName="password" [class.error-outline]="userInfo.get('password').invalid && userInfo.get('password').touched" id="coupon-email" type="password" name="category-email" placeholder="Ingresa un password">
                <p class="error-label" *ngIf="userInfo.get('password').invalid && userInfo.get('password').touched">Por favor ingresa una contraseña mayor a 6 caracteres</p>
                <p class="bold text-14" style="margin-top: 15px; color: rgb(253, 59, 59);">{{errorText}}</p>
                <button class="primary-btn" [class.disabled-btn]="userInfo.invalid" (click)="createUser()">AGREGAR</button>
            </div>
           </div>
           <button class="primary-btn" (click)="showUsersTable()">VER USUARIOS</button>
    </div>
    <div class="dashboard-content" *ngIf="showUsers">
        <div class="show-users-header">
            <p (click)="hideUsersTable()" class="bold"><i class="fas fa-chevron-left"></i> REGRESAR</p>
            <div class="users-searchbar">
                <i class="fas fa-search"></i>
                <input type=text placeholder="Buscar usuario por nombre o correo" (keyup)="filterList(search.value)" #search>
            </div>
        </div>
        <div class="users-table">
            <div class="table-header">
                <p>Nombre</p>
                <p>Correo</p>
                <p>Teléfono</p>
            </div>
            <div class="table-body">
                <div class="table-row" *ngFor="let user of usersFiltered">
                    <p>{{user.name}}</p>
                    <p>{{user.email}}</p>
                    <p>{{user.phone}}</p>
                    <i class="fas fa-qrcode" (click)="showUserQR(user)"></i>
                </div>
            </div>
       </div>
    </div>
</div>

<div class="modal" [class.modal-show]="showQR">
    <div class="modal-content" [class.edit-show]="showQR">
        <i class="fa fa-times" (click)="hideUserQR()"></i>
        <p style="font-size: 24px; font-weight: 700;">{{userTemp.name}}</p>
        <ngx-qrcode
            [elementType]="elementType"
            [errorCorrectionLevel]="correctionLevel"
            [value]="userQR"
            cssClass="qrcode" class="qr-code"></ngx-qrcode>
        <p class="download-qr blue-text bold" (click)="downloadQRcode()">Descargar código QR</p>
    </div>
</div>