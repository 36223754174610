import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { AddressService } from 'src/app/services/address.service';
import { AuthService } from 'src/app/services/auth.service';
import { OrdersService } from 'src/app/services/orders.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
  user = {
    email: "",
    name: "",
    phone: "",
    role: "",
    paymentMethods: []
  };
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  userID;
  userUrl;
  orderList;
  addresses;
  value

  showOrderDetail = false;
  orderToShow = {
    date: "",
    address: "",
    address_2: "",
    city: "",
    state: "",
    postalCode: "",
    contactPhone: "",
    contactEmail: "",
    extraComments: "",
    discountCode: '',
    products: [],
    totalCost: 0,
    discount: 0,
    subtotal: 0,
    shippingCost: 0,
    status: ''
  }

  showAddressModal = false
  addressToEdit = {
    address: "",
    address_2: "",
    city: "",
    state: "",
    postalCode: "",
    contactPhone: "",
    contactEmail: "",
    extraComments: "",
    id: ""
  }

  addressInfo = new FormGroup({
    address: new FormControl(this.addressToEdit.address, Validators.required),
    address_2: new FormControl(this.addressToEdit.address_2),
    city: new FormControl(this.addressToEdit.city, Validators.required),
    state: new FormControl(this.addressToEdit.state, Validators.required),
    postal: new FormControl(this.addressToEdit.postalCode, Validators.required),
    phone: new FormControl(this.addressToEdit.contactPhone, [
      Validators.required,
      Validators.pattern("^[0-9]+$"),
      Validators.minLength(8),
      Validators.maxLength(10)
    ]),
    email: new FormControl(this.addressToEdit.contactEmail, [
      Validators.required,
      Validators.email
    ]),
    extraComments: new FormControl(this.addressToEdit.extraComments)
  })

  constructor(public fireAuth: AngularFireAuth,
    private router: Router,
    public orderService: OrdersService,
    public authService: AuthService,
    public userService: UserService,
    public addressService: AddressService) { }

  ngOnInit(): void {
    this.authService.getCurrentUser().then((res) => {
      if (res) {
        this.userID = res.uid
        this.value = `followears.com/#/ver-perfil/${this.userID}`
        this.userUrl = `/ver-perfil/${this.userID}`
        this.userService.getUserById(res.uid).subscribe((res) => {
          this.user = res;
        })
        this.orderService.getOrdersByUserId(res.uid).subscribe((res) => {
          for (let order of res) {
            order.date = this.transformDate(order.date)
          }
          this.orderList = res;
        })
        this.addressService.getAddresssByUserId(res.uid).subscribe((res) => {
          this.addresses = res;
        })
      }
    })
  }

  transformDate(date) {
    let d;
    if (date != undefined) d = new Date(date.seconds * 1000);
    if (date == undefined) d = new Date();
    let options = { day: "numeric", month: "long", year: "numeric" };
    return d.toLocaleDateString("es-ES", options);
  }

  downloadQRcode() {
    const fileNameToDownload = 'codigo-qr';
    const base64Img = document.getElementsByClassName('bshadow')[0].children[0]['src'];
    fetch(base64Img)
      .then(res => res.blob())
      .then((blob) => {
        // IE
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, fileNameToDownload);
        } else { // Chrome
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = fileNameToDownload;
          link.click();
        }
      })
  }

  removeCard(index) {
    this.user.paymentMethods.splice(index, 1)
    this.userService.updatePaymentMethods(this.userID, this.user.paymentMethods)
  }

  removeAddress(id) {
    this.addressService.removeAddress(id);
  }

  showOrderDetailModal(order) {
    this.showOrderDetail = true
    this.orderToShow = order
  }

  hideOrderDetailModal() {
    this.showOrderDetail = false
  }

  showEditAddress(address) {
    this.showAddressModal = true
    this.addressToEdit = address
    this.addressInfo.controls.address.setValue(this.addressToEdit.address)
    this.addressInfo.controls.address_2.setValue(this.addressToEdit.address_2)
    this.addressInfo.controls.city.setValue(this.addressToEdit.city)
    this.addressInfo.controls.state.setValue(this.addressToEdit.state)
    this.addressInfo.controls.postal.setValue(this.addressToEdit.postalCode)
    this.addressInfo.controls.phone.setValue(this.addressToEdit.contactPhone)
    this.addressInfo.controls.email.setValue(this.addressToEdit.contactEmail)
    this.addressInfo.controls.extraComments.setValue(this.addressToEdit.extraComments)
  }

  hideEditAddress() {
    this.showAddressModal = false
  }

  saveAddressChanges() {
    const tempAddress = {
      address: this.addressInfo.controls.address.value,
      address_2: this.addressInfo.controls.address_2.value,
      city: this.addressInfo.controls.city.value,
      state: this.addressInfo.controls.state.value,
      postalCode: this.addressInfo.controls.postal.value,
      contactPhone: this.addressInfo.controls.phone.value,
      contactEmail: this.addressInfo.controls.email.value,
      extraComments: this.addressInfo.controls.extraComments.value,
    }
    this.addressService.updateAddress(this.addressToEdit.id,tempAddress)
    this.showAddressModal = false
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode == 46) return true
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  onLogout() {
    this.fireAuth.signOut().then(() => {
      this.router.navigate(['login'])
    }
    )
  }

}
