<div class="payment-wrapper">
    <div class="payment-container">
        <div class="shipment-info">
            <p class="payment-title text-12 bold">ENVIO</p>
            <p>Los envios se realizan apartir de 2-5 días habiles, y no se por donde se envien</p>
            <div class="shipping-row">
                <form>
                    <div class="form-group" [formGroup]="shippingInfo">
                        <ng-container *ngIf="addresses && addresses.length > 0">
                            <label for="user-chosen-address"  class="text-12 bold">Elige una opción para entregar</label>
                            <select name="user-chosen-address" formControlName="chosenAddress" class="">
                                <option selected value=''>Nueva dirección</option>
                                <option *ngFor="let address of addresses; let i = index" [value]="i">{{address.address}}</option>
                            </select>
                        </ng-container>
                        <ng-container *ngIf="shippingInfo.get('chosenAddress').value == ''">
                            <div class="column-inputs">
                                <label for="user-address" class="text-12 bold">Dirección de la calle *</label>
                                <input formControlName="address" [class.error-outline]="shippingInfo.get('address').invalid && shippingInfo.get('address').touched" type="text" id="user-address" placeholder="Número de casa y nombre de la calle">
                                <input formControlName="address_2" type="text" id="user-address-2" placeholder="Colonia, Apartamento, habitación, etc. (opcional)">
                                <p class="error-label" *ngIf="shippingInfo.get('address').invalid && shippingInfo.get('address').touched">Por favor ingresa una dirección</p>
                            </div>
                            <div class="column-inputs">
                                <label for="user-city" class="text-12 bold">Ciudad *</label>
                                <input formControlName="city" [class.error-outline]="shippingInfo.get('city').invalid && shippingInfo.get('city').touched" type="text" id="user-city" placeholder="Ciudad dónde entregar">
                                <p class="error-label" *ngIf="shippingInfo.get('city').invalid && shippingInfo.get('city').touched">Por favor ingresa una ciudad válida</p>
                            </div>
                            <div class="row-inputs">
                                <div class="column-inputs" id="state-field">   
                                    <label for="user-state" class="text-12 bold">Región / Estado *</label>
                                    <select formControlName="state" name="state">
                                        <option value="" disabled selected>Seleccione una opción</option>
                                        <option value="Aguascalientes">Aguascalientes</option>
                                        <option value="Baja California">Baja California</option>
                                        <option value="Baja California Sur">Baja California Sur</option>
                                        <option value="Campeche">Campeche</option>
                                        <option value="Chiapas">Chiapas</option>
                                        <option value="Chihuahua">Chihuahua</option>
                                        <option value="CDMX">CDMX</option>
                                        <option value="Coahuila">Coahuila</option>
                                        <option value="Colima">Colima</option>
                                        <option value="Durango">Durango</option>
                                        <option value="Estado de México">Estado de México</option>
                                        <option value="Guanajuato">Guanajuato</option>
                                        <option value="Guerrero">Guerrero</option>
                                        <option value="Hidalgo">Hidalgo</option>
                                        <option value="Jalisco">Jalisco</option>
                                        <option value="Michoacán">Michoacán</option>
                                        <option value="Morelos">Morelos</option>
                                        <option value="Nayarit">Nayarit</option>
                                        <option value="Nuevo León">Nuevo León</option>
                                        <option value="Oaxaca">Oaxaca</option>
                                        <option value="Puebla">Puebla</option>
                                        <option value="Querétaro">Querétaro</option>
                                        <option value="Quintana Roo">Quintana Roo</option>
                                        <option value="San Luis Potosí">San Luis Potosí</option>
                                        <option value="Sinaloa">Sinaloa</option>
                                        <option value="Sonora">Sonora</option>
                                        <option value="Tabasco">Tabasco</option>
                                        <option value="Tamaulipas">Tamaulipas</option>
                                        <option value="Tlaxcala">Tlaxcala</option>
                                        <option value="Veracruz">Veracruz</option>
                                        <option value="Yucatán">Yucatán</option>
                                        <option value="Zacatecas">Zacatecas</option>
                                    </select>
                                    <p class="error-label" *ngIf="shippingInfo.get('state').invalid && shippingInfo.get('state').touched">Seleccione una opción</p>
                                </div>
                                <div class="column-inputs" id="postal-field">
                                    <label for="user-postal" class="text-12 bold">Código postal *</label>
                                    <input formControlName="postal" (keypress)="numberOnly($event)" [class.error-outline]="shippingInfo.get('postal').invalid && shippingInfo.get('postal').touched" type="text" id="user-postal" placeholder="02391">
                                    <p class="error-label" *ngIf="shippingInfo.get('postal').invalid && shippingInfo.get('postal').touched">Código postal inválido</p>
                                </div>
                            </div>
                            <div class="column-inputs">
                                <label for="user-phone" class="text-12 bold">Teléfono de contacto *</label>
                                <input formControlName="phone" (keypress)="numberOnly($event)" [class.error-outline]="shippingInfo.get('phone').invalid && shippingInfo.get('phone').touched" type="text" id="user-phone" placeholder="Teléfono de contacto">
                                <p class="error-label" *ngIf="shippingInfo.get('phone').invalid && shippingInfo.get('phone').touched">Por favor ingresa un teléfono válido</p>
                            </div>
                            <div class="column-inputs">
                                <label for="user-email" class="text-12 bold">Correo electrónico de contacto *</label>
                                <input formControlName="email" [class.error-outline]="shippingInfo.get('email').invalid && shippingInfo.get('email').touched" type="text" id="user-email" placeholder="Correo de contacto">
                                <p class="error-label" *ngIf="shippingInfo.get('email').invalid && shippingInfo.get('email').touched">Por favor ingresa un correo válido</p>
                            </div>
                            <label for="user-extra-info" class="text-12 bold">Indicaciones Extra</label>
                            <textarea formControlName="extraComments" id="user-extra-info" placeholder="Dejar con el policia en caso de no estar,"></textarea>
                        </ng-container>
                        
                    </div>
                </form>
            </div>
        </div>
        <div class="payment-info">
            <p class="payment-title text-12 bold">PAGO</p>
            <p>Todas nuestras transacciones son seguras y protegidas por Stripe.</p>
            <div class="payment-methods">
                <div class="payment-method" id="stripe-payment">
                    <div class="payment-radio-btn active-radio-btn"></div>
                    <p class="bold">Tarjeta de Crédito</p>
                </div>
                <div class="stripe-form column-inputs active-form">
                    <form>
                        <div class="form-group" [formGroup]="cardInfo">
                            <ng-container *ngIf="user && user.paymentMethods && user.paymentMethods.length > 0">
                                <label for="user-chosen-card"  class="text-12 bold">Elige una opción para el pago</label>
                                <select name="user-chosen-card" formControlName="chosenCard">
                                    <option select value=''>Nuevo método de pago</option>
                                    <option *ngFor="let method of user.paymentMethods; let i = index" [value]="i">{{method.brand | uppercase}} que termina en {{method.last4}}</option>
                                </select>
                            </ng-container>
                            <ng-container *ngIf="cardInfo.get('chosenCard').value == ''">
                                <label for="card-holder" class="text-12 bold">Nombre del tarjetahabiente</label>
                            <input formControlName="cardHolder" [class.error-outline]="cardInfo.get('cardHolder').invalid && cardInfo.get('cardHolder').touched" type="text" name="holder" class="stripe-form-input" id="card-holder" placeholder="Nombre como aparece en la tarjeta">
                            <p class="error-label" *ngIf="cardInfo.get('cardHolder').invalid && cardInfo.get('cardHolder').touched">Por favor ingresa un nombre</p>
                            <label for="card-number" class="text-12 bold">Número de tarjeta</label>
                            <input formControlName="cardNumber" (keypress)="numberOnly($event)" [class.error-outline]="cardInfo.get('cardNumber').invalid && cardInfo.get('cardNumber').touched" type="text" name="number" class="stripe-form-input" id="card-number" placeholder="1111 2222 3333 4444">
                            <p class="error-label" *ngIf="cardInfo.get('cardNumber').invalid && cardInfo.get('cardNumber').touched">Por favor ingresa un número válido</p>
                            <div class="row-inputs">
                                <div class="column-inputs col-3">
                                    <label for="card-month" class="text-12 bold">Mes de vencimiento</label>
                                    <select formControlName="cardMonth" name="month" color="primary">
                                        <option disabled>Mes</option>
                                        <option value="01">Enero</option>
                                        <option value="02">Febrero</option>
                                        <option value="03">Marzo</option>
                                        <option value="04">Abril</option>
                                        <option value="05">Mayo</option>
                                        <option value="06">Junio</option>
                                        <option value="07">Julio</option>
                                        <option value="08">Agosto</option>
                                        <option value="09">Septiembre</option>
                                        <option value="10">Octubre</option>
                                        <option value="11">Noviembre</option>
                                        <option value="12">Diciembre</option>
                                    </select>
                                    <p class="error-label" *ngIf="cardInfo.get('cardMonth').invalid && cardInfo.get('cardMonth').touched">Por favor ingresa un número válido</p>
                                </div>
                                <div class="column-inputs col-3">
                                    <label for="card-year" class="text-12 bold">Año de vencimiento</label>
                                    <select formControlName="cardYear" name="year">
                                        <option [value]=year *ngFor="let year of availableYears">{{year}}</option>
                                    </select>
                                    <p class="error-label" *ngIf="cardInfo.get('cardYear').invalid && cardInfo.get('cardYear').touched">Por favor ingresa un año válido</p>
                                </div>
                                <div class="column-inputs col-3">
                                    <label for="card-cvc" class="text-12 bold">CVC</label>
                                    <input type="text" name="cvc" (keypress)="numberOnly($event)" [class.error-outline]="cardInfo.get('cardCvc').invalid && cardInfo.get('cardCvc').touched" class="stripe-form-input" id="card-cvc" placeholder="123" formControlName="cardCvc">
                                    <p class="error-label" *ngIf="cardInfo.get('cardCvc').invalid && cardInfo.get('cardCvc').touched">Por favor ingresa un número válido</p>
                                </div>
                            </div>
                            </ng-container>
                        </div>
                    </form>
                </div>
                <!--<div class="payment-method" id="paypal-payment" (click)="showPaypalForm()">
                    <div class="payment-radio-btn" [ngClass]="{'active-radio-btn':showPaypal}"></div>
                    <p class="bold">Paypal</p>
                </div>
                <div class="paypal-form" [ngClass]="{'active-form':showPaypal}">
                    <img src="../../../assets/Img/paypal-icon-payment.png">
                    <p>Cuando hagas click en el botón de “Completar orden” te saldra una ventana de paypal para completar el pago.</p>
                </div>-->
            </div>
        </div>
    </div>
    <div class="payment-cart">
        <div class="cart-products">
            <div class="cart-product" *ngFor="let item of cart.items; let i = index">
                <img class="cart-product-image" [src]=item.image>
                <div class="cart-product-details">
                    <p class="cart-product-name text-16">{{item.name}}</p>
                    <p class="cart-product-price text-16 bold">${{item.price}} <span class="text-12">mxn</span></p>
                    <input type="number" min="1" placeholder="1" [value]=item.quantity class="cart-product-number" name="quantity" #quantity (keypress)="numberOnly($event)" (change)="updateCartAmount(i,quantity.value)">
                </div>
                <p class="cart-product-remove-btn" (click)="removeFromCart(i)"><i class="fas fa-times"></i></p>
            </div>
        </div>
        <div class="coupon-section">
            <input type="text" placeholder="Ingresa el código" class="coupon-code" #coupon>
            <button class="coupon-btn bold text-16" (click)="applyCoupon(coupon.value)">APLICAR</button>
        </div>
        <p class="error-text">{{couponError}}</p>
        <div class="cart-numbers">
            <p class="cart-price">Envio:<span style="float: right;">{{shippingCost | currency}}&nbsp;<span class="text-12">mxn</span></span></p>
            <p class="cart-price">Subotal:<span style="float: right;">{{totalCost + shippingCost | currency}}&nbsp;<span class="text-12">mxn</span></span></p>
            <p class="cart-price" style="color: #FB07FE" *ngIf="discount != 0">Descuento:<span style="float: right;">- {{discount | currency}}&nbsp;<span class="text-12">mxn</span></span></p>
        </div>
        <p class="bold cart-total">Total: <span style="float: right; color: #04FAFC;" class="cart-total-number bold">{{totalCost + shippingCost - discount | currency}} <span class="text-20">mxn</span></span></p>
        <button class="pay-btn" (click)="payCart()" [class.btn-disabled]="!shippingInfo.valid || !cardInfo.valid || cart.items.length == 0 || !user">PAGAR</button>
        <div [class.btn-paypal-disabled]="!shippingInfo.valid || cart.items.length == 0 || !user" #paypal></div>
        <p class="error-pay-text" *ngIf="!user">Debes iniciar sesión para poder realizar una compra</p>
        <p class="error-pay-text" *ngIf="cart.items.length == 0">Debes agregar artículos a tu carrito antes de proceder al pago</p>
        <ngx-spinner [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium">
            <p class="loading">Procesando pago...</p>
        </ngx-spinner>
    </div>
</div>
