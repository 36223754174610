import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-profile-view',
  templateUrl: './profile-view.component.html',
  styleUrls: ['./profile-view.component.scss']
})
export class ProfileViewComponent implements OnInit {
  user;

  constructor(public route: ActivatedRoute, public userService : UserService) { }

  ngOnInit(): void {
    let id = this.route.snapshot.params['id'];
    this.userService.getUserById(id).subscribe((res) =>{
      this.user = res;
    })
  }

}
