import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CategoriesService } from 'src/app/services/categories.service';

@Component({
  selector: 'categories',
  templateUrl: './admin-categories.component.html',
  styleUrls: ['./admin-categories.component.scss']
})
export class AdminCategoriesComponent implements OnInit {

  categoryInfo = new FormGroup({
    categoryName: new FormControl('', Validators.required)
  })

  categories
  edit_index=-1
  edit_name=''
  

  constructor(
    public fireAuth : AngularFireAuth,
    public router: Router,
    public categoriesService: CategoriesService
  ) { }

  ngOnInit(): void {
    this.categoriesService.getCategories().subscribe((res) => {
      this.categories = res.sort(this.compare);
    })
  }

  compare(a,b){
    if(a.name.toLowerCase() > b.name.toLowerCase())return 1
    return -1
  }

  createCategory(){
    const name = this.categoryInfo.controls.categoryName.value
    this.categoriesService.addCategory({name:name})
    this.categoryInfo.controls.categoryName.reset()
  }

  showEditCategoty(index,name){
    this.edit_index=index
    this.edit_name=name
  }

  removeCategory(id){
    this.categoriesService.removeCategory(id)
  }

  saveCategoryChange(id){
    this.categoriesService.updateCategory(id,{name: this.edit_name})
    this.edit_index=-1
  }

  hideEditCategory(){
    this.edit_index=-1
  }

  onLogout(){
    this.fireAuth.signOut().then(() =>{
        this.router.navigate(['login'])
      }
    )
  }

}
