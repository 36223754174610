import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Product } from '../shared/product'
import { ShoppingCart } from '../shared/shoppingCart'

@Injectable({
  providedIn: 'root'
})
export class ShoppingCartService {
  public stringVar = new Subject<ShoppingCart>();
  public stringVar$ = this.stringVar.asObservable() //Has a $

  constructor() {
    this.setTokens({userID: "", items: []})
  }

  public setTokens(cart: ShoppingCart) {
    this.stringVar.next(cart);
  }

  public getTokens() {
    return this.stringVar$
  }
}
