import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CategoriesService } from 'src/app/services/categories.service';
import { ProductsService } from 'src/app/services/products.service';
import { Upload } from '../../shared/upload'

@Component({
  selector: 'products',
  templateUrl: './admin-products.component.html',
  styleUrls: ['./admin-products.component.scss']
})
export class AdminProductsComponent implements OnInit {
  products
  productsFiltered
  productToEdit = {
    name: "",
    category: "",
    sizes: [],
    colors: [],
    images: [],
    description: "",
  }
  categories

  showCreateProductForm = false;
  showEditProductForm = false;

  showAddColor = false;
  showAddSize = false

  productId = ''

  colors = [{
    code: '#FFFFFF',
    name: 'Blanco'
  }]

  sizes = [
    'XS'
  ]

  images = []

  imageFiles = []

  originalImages = []

  productInfo = new FormGroup({
    name: new FormControl('', Validators.required),
    description: new FormControl('', Validators.required),
    price: new FormControl(0, [Validators.required, Validators.min(1)]),
    category: new FormControl('',Validators.required),
    colorName: new FormControl(''),
    colorCode: new FormControl(''),
    size: new FormControl('')
  })

  constructor(
    public fireAuth : AngularFireAuth,
    public router: Router,
    public productsService: ProductsService,
    public categoriesService: CategoriesService
  ) { }

  ngOnInit(): void {
    this.productsService.getProducts().subscribe((res) => {
      /*for(const item of res){
        this.categoriesService.
      }*/
      this.products = res.sort(this.compare);
      this.productsFiltered = this.products
    })
    this.categoriesService.getCategories().subscribe((res) => {
      this.categories = res.sort(this.compare)
    })
  }

  filterList(searchTerm:string){
    this.productsFiltered = this.products.filter(function (el) {
      var name:string = el.name;
      var id:string = el.id
      //var category:string = el.category
      // || category.toLowerCase().includes(searchTerm.toLowerCase())
      return name.toLowerCase().includes(searchTerm.toLowerCase()) || id.toLowerCase().includes(searchTerm.toLowerCase())
    });
  }

  compare(a,b){
    if(a.name > b.name)return 1
    return -1
  }

  showCreateProduct(){
    this.showCreateProductForm = true
  }

  hideCreateProduct(){
    this.showCreateProductForm = false
    this.colors = []
    this.sizes = []
    this.images = []
    this.productInfo.reset()
  }

  createProduct(){
    let imageUploads = []
    let tempImage
    for(const image of this.imageFiles){
      tempImage = new Upload(image)
      imageUploads.push(tempImage)
    }
    const productTemp = {
      name: this.productInfo.controls.name.value,
      description: this.productInfo.controls.description.value,
      price: this.productInfo.controls.price.value,
      sizes: this.sizes,
      colors: this.colors,
      images: imageUploads,
      category: this.productInfo.controls.category.value
    }
    this.productsService.addProduct(productTemp)
    this.hideCreateProduct()
  }

  showEditProduct(product){
    this.showEditProductForm = true
    this.productInfo.controls.name.setValue(product.name)
    this.productInfo.controls.description.setValue(product.description)
    this.productInfo.controls.category.setValue(product.category)
    this.productInfo.controls.price.setValue(product.price)
    if(product.sizes)this.sizes = product.sizes
    if(product.colors)this.colors = product.colors
    if(product.images){
      this.images = product.images
      for(const image of product.images){
        this.originalImages.push(image)
      }
    }
    this.productId = product.id
  }

  editProduct(id){
    let imageUploads = []
    let tempImage
    for(const image of this.imageFiles){
      tempImage = new Upload(image)
      imageUploads.push(tempImage)
    }
    const productTemp = {
      name: this.productInfo.controls.name.value,
      description: this.productInfo.controls.description.value,
      price: this.productInfo.controls.price.value,
      sizes: this.sizes,
      colors: this.colors,
      images: this.originalImages,
      category: this.productInfo.controls.category.value
    }
    this.productsService.updateProduct(id,productTemp,imageUploads)
    this.hideEditProduct()
  }

  hideEditProduct(){
    this.showEditProductForm = false
    this.imageFiles = []
    this.colors = []
    this.sizes = []
    this.images = []
    this.originalImages = []
    this.productInfo.reset()
  }

  showColor(){
    this.showAddColor = true
  }

  addColor(){
    this.colors.push({code:this.productInfo.controls.colorCode.value,name:this.productInfo.controls.colorName.value})
    this.productInfo.controls.colorCode.reset()
    this.productInfo.controls.colorName.reset()
    this.showAddColor = false
  }

  removeColor(index){
    this.colors.splice(index,1)
  }

  showSize(){
    this.showAddSize = true
  }

  addSize(){
    this.sizes.push(this.productInfo.controls.size.value)
    this.productInfo.controls.size.reset()
    this.showAddSize = false
  }

  removeSize(index){
    this.sizes.splice(index,1)
  }

  onFileChanged(event) {
    const imageTemp = event.target.files[0];
    this.imageFiles.push(event.target.files[0])

    console.log(this.originalImages.length)

    const reader = new FileReader();
    reader.readAsDataURL(imageTemp); 
    reader.onload = (_event) => { 
      this.images.push(reader.result);
    }
  }

  removeImage(index){
    this.images.splice(index,1)
    if(index>this.originalImages.length-1){
      this.imageFiles.splice(index-this.originalImages.length-1,1)
    }
    if(index<this.originalImages.length){
      this.productsService.deleteImageFromFirestore(this.originalImages[index]).then(ref => {
        this.originalImages.splice(index,1)
      },(error) => this.originalImages.splice(index,1))
    }
  }

  onLogout(){
    this.fireAuth.signOut().then(() =>{
        this.router.navigate(['login'])
      }
    )
  }

}
