<div id="first-section">
    <img src="../../../assets/Img/Landing-blue-poligon.png" id="first-vector">
    <div class="first-section-text">
        <p class="neon-text text-50 tu-perfil-text">LLEVA FOLLOW WEARS A TU EMPRESA</p>
        <p class="text-18 justified landing-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer vulputate suspendisse lectus dignissim ornare. Risus lorem faucibus sem arcu.</p>
        <a class="buy-button text-20" routerLink="/tienda">COMPRAR AHORA</a>
    </div>
    <img src="../../../assets/Img/hoodie_back.png" class="hoodie-img">
    <img src="../../../assets/Img/pink_dots.png" class="pink-dots">
    <img src="../../../assets/Img/blue_dots.png" class="blue-dots">
    <img src="../../../assets/Img/landing-blue-big-triangle.png" class="blue-triangle-big">
</div>

<div class="second-section">
    <img src="../../../assets/Img/FW_QR.png" class="fw-qr">
    <img src="../../../assets/Img/IphoneX-1.png" class="iPhoneX">
    <div class="second-section-text">
        <p class="neon-text text-50">¿CÓMO FUNCIONA?</p>
        <p class="text-18 second-section-description justified">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer vulputate suspendisse lectus dignissim ornare. Risus lorem faucibus sem arcu.
            Laoreet leo id egestas amet vitae scelerisque.</p>
        <a class="buy-button text-20">OBTENLO AHORA</a>
    </div>
</div>

<div class="tidbits-section">
    <div class="tidbit">
        <img src="../../../assets/Img/tidbit1.png">
        <p class="text-18">Lleva tu marca a todas partes con nuestra línea de ropa urbana.</p>
    </div>
    <div class="tidbit">
        <img src="../../../assets/Img/tidbit2.png" style="width: 60px;">
        <p class="text-18">No pierdas la oportunidad de hacer crecer tu comunidad.</p>
    </div>
    <div class="tidbit">
        <img src="../../../assets/Img/tidbit3.png">
        <p class="text-18"><span class="bold">No más tarjetas de contácto</span><br>Lleva tu marca a todas partes con nuestra línea de ropa urbana.</p>
    </div>
    <div class="tidbit">
        <img src="../../../assets/Img/tidbit4.png">
        <p class="text-18"><span class="bold">Eres tu propia marca</span><br>Tú serás tu propia marca y podrás conectar en segundos con todo el mundo.</p>
    </div>
</div>
<div class="fourth-section">
    <p class="neon-text text-50 lleva-follow-title">LLEVA FOLLOW WEARS A TU EMPRESA</p>
    <p class="text-18 lleva-follow-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. At sodales nunc scelerisque tincidunt diam enim. Eget nunc fermentum, vel placerat praesent tellus nisl porttitor.<br><br>

        Volutpat in adipiscing massa enim, accumsan pretium, faucibus nunc ipsum. Vel rhoncus vulputate lectus est tempus.<br><br>
       
       Laoreet arcu nisl sed fermentum mi eu vulputate. Pulvinar ultrices morbi tortor, velit id. Fringilla nulla interdum enim.</p>
    <button class="cotizacion-btn text-20">PIDE UNA COTIZACIÓN</button>
</div>

<div class="fifth-section">
</div>

<div class="sixth-section">
    <div>
        <p class="neon-text text-50 contacto-title">PONTE EN CONTÁCTO CON NOSOTROS</p>
        <p class="contacto-text text-18">Lorem ipsum dolor sit amet, consectetur adipiscing elit. At sodales nunc scelerisque tincidunt diam enim. Eget nunc fermentum, vel placerat praesent tellus nisl porttitor.<br><br>

Volutpat in adipiscing massa enim, accumsan pretium, faucibus nunc ipsum. Vel rhoncus vulputate lectus est tempus.<br><br>
           
Laoreet arcu nisl sed fermentum mi eu vulputate. Pulvinar ultrices morbi tortor, velit id. Fringilla nulla interdum enim.</p>
    </div>
    <div>
        <label for="nombre" class="bold">NOMBRE</label>
        <input type="text" class="text-14" id="nombre-input" name="nombre">
        <label for="correo" class="bold">CORREO</label>
        <input type="text" class="text-14" id="correo-input" name="correo">
        <label for="mensaje" class="bold">MENSAJE</label>
        <textarea class="text-14" id="mensaje-input" name="mensaje"></textarea>
        <button class="msg-btn text-20">MANDAR CORREO</button>
    </div>
</div>

<div class="footer">
    <img src="../../../assets/Img/fw-logo-short.png" class="logo-footer">
    <div>
        <a><img src="../../../assets/Img/fw-instagram.png" class="footer-social"></a>
        <a><img src="../../../assets/Img/fw-twitter.png" class="footer-social"></a>
        <a><img src="../../../assets/Img/fw-facebook.png" class="footer-social"></a>
    </div>
    <p class="text-20">Follow Wears ©. Todos los derechos reservados. 2020
        <a><span routerLink="/terminos">Terminos y Condiciones</span> | <span routerLink="/aviso">Aviso de privacidad</span></a></p>
</div>