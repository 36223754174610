<div class="login-wrapper">
    <div class="login-container">
        <img src="../../../assets/Img/fw-logo-short.png" id="fw-logo-short">
        <ng-container *ngIf="!passwordReset">
            <div class="input-text">
                <label class="text-10 bold">CORREO</label><input type="text" placeholder="Correo" [(ngModel)]="email">
            </div>
            <div class="input-text">
                <label class="text-10 bold">CONTRASEÑA</label><input type="password" placeholder="Contraseña" [(ngModel)]="password">
            </div>
        </ng-container>
        <ng-container *ngIf="passwordReset">
            <div class="input-text">
                <label class="text-10 bold">CORREO</label><input type="text" placeholder="Correo para restaurar" [(ngModel)]="emailRestore">
            </div>
        </ng-container>
        <p class="error-text">{{errorText}}</p>
        <button (click)="Login(password.value)" class="login-btn text-16 bold" *ngIf="!passwordReset">INICIAR SESIÓN</button>
        <button (click)="resetPassword()" class="login-btn text-16 bold" *ngIf="passwordReset">RESTAURAR CONTRASEÑA</button>
        <a class="password-restore-link" (click)="showPasswordReset()" *ngIf="!passwordReset">¿Olvidaste tu contraseña?</a>
        <a class="password-restore-link" routerLink="/signup" *ngIf="!passwordReset">Crear cuenta</a>
        <a class="password-restore-link" (click)="hidePasswordReset()" *ngIf="passwordReset">Regresar</a>
        <p class="rights-text">Follow Wears. Todos los derechos reservados. 2021</p>
    </div>
</div>