import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CouponsService {
  private couponList;
  private couponCollection: AngularFirestoreCollection

  constructor(private firestore: AngularFirestore) {
    this.couponCollection = this.firestore.collection('coupons');
    this.couponList = this.couponCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
  }

  addCoupon(coupon){
    return this.couponCollection.add(coupon)
  }

  updateCoupon(id, coupon){
    return this.couponCollection.doc(id).update(coupon);
  }

  getCoupons(){
    return this.couponList;
  }

  removeCoupon(id){
    return this.couponCollection.doc(id).delete();
  }
  
}
