<div class="terms-wrapper">
    <h1>Aceptación de los Términos y Condiciones</h1>
    <pre>
Para contratar el Servicio Follow Wears (en lo sucesivo el "Servicio" o el "Proveedor"), se deberá dar lectura íntegra a los siguientes términos y condiciones (los “Términos y Condiciones”), y cumplir totalmente con los requisitos y obligaciones aquí establecidos. Al ingresar, visitar, navegar, utilizar los Medios y/o aceptar los productos, el usuario acepta estar obligado por los mismos, así como sujetarse a las Políticas o Aviso de Privacidad del Proveedor. Si no está de acuerdo con estos Términos y Condiciones, no deberá utilizar los Medios, estos Términos de Uso son un contrato que rige la relación entre usted y el Proveedor.

El Servicio se ejecutará dentro de la página electrónica https://followears.com/, (cuando exista una referencia indistinta a cualquiera de los medios para la contratación del Servicio, se le denominará los "Medios").

<span class="terms-title">Definiciones</span>

Además de los términos en su caso definidos en los párrafos que preceden, para efectos de estos Términos y Condiciones se entenderá por:

• Sitio WEB.- La dirección electrónica https://followears.com/, a través de la cual se podrá contratar el Servicio, sujetándose los Usuarios y Consumidores a los presentes Términos y Condiciones y al Aviso de Privacidad.
• Cuenta.- Al registro individual del Usuario que lo identificará y distinguirá como tal de los demás contratantes, misma que será necesaria para la contratación del Servicio.
• Usuario.- A la persona física mayor de edad y que cuente con capacidad de ejercicio, conforme a las leyes de la República Mexicana, y que cumpla con los requisitos de contratación, ingrese al Servicio y obtenga su Cuenta individual de Usuario.
• Consumidor.- A la persona física que adquiere, disfruta o recibe como destinatario final el producto objeto del Servicio, es decir, para consumo propio o de otras personas físicas.
Todas las obligaciones y requisitos consignados en los presentes Términos y Condiciones aplicarán para el Consumidor como si se tratase del Usuario, en el entendido que la persona que contrata el Servicio (Usuario) pueda ser distinta al Consumidor.
• Proveedor.- D’TAV’S, S.A. de C.V. , el cual tiene su domicilio en Barranca Seca 10, Huitznahuac, Chiautla, 56030, México.
• Territorio: La República Mexicana, limitándose a las áreas de cobertura de Servicio.
• Garantía: Se refiere a la reposición del producto y/o reembolso de los montos pagados por el cliente, de acuerdo con lo acordado.
• Entrega no reconocida: Cuando el paquete sea recibido por una persona que no sea el cliente y que no sea reconocida por este último.

En caso de haber más definiciones en el cuerpo de los presentes Términos y Condiciones se señalará enseguida su significado.
Los términos referidos en este apartado tendrán igual significado ya sea que se utilicen en singular o plural.

<span class="terms-title">Modificaciones a los Términos y Condiciones</span>

El Proveedor se reserva el derecho de cambiar ocasionalmente estos Términos y Condiciones de acuerdo con las necesidades del Servicio, con o sin notificación. La versión más reciente de estos Términos y Condiciones estará disponible en el Sitio WEB, misma que reemplazará las versiones anteriores. El uso de los Medios una vez que se hayan realizado las modificaciones a estos Términos y Condiciones significa que se aceptan dichos cambios.

<span class="terms-title">Privacidad</span>

Para más información, consulta nuestra <a routerLink="/aviso">política de privacidad.</a>

<span class="terms-title">De la Cuenta</span>

El Usuario es responsable de mantener su Cuenta, incluyendo los datos de su Usuario y contraseña, como confidenciales reconociendo que es responsable de todas las actividades que se desarrollen utilizando su Cuenta o contraseña.

Asimismo, el Usuario podrá tener única y exclusivamente una Cuenta, pudiendo ser causa de cancelación conservas más de una Cuenta por Usuario.

<span class="terms-title">Cancelación de la cuenta</span>

De detectarse comportamiento fraudulento, la existencia de varias Cuentas vinculadas a un mismo Usuario, alguna actividad que vaya en contra de los Términos y Condiciones aquí previstos o ilícita, el Proveedor se reserva el derecho de cancelar, inhabilitar o suspender alguna o todas las Cuenta(s) en cualquier momento, y proceder de forma legal según sea el caso y el daño realizado.

<span class="terms-title">Del Uso del Sitio Web</span>

El Usuario se compromete, además de lo establecido en los presentes, a:(a) utilizar el Sitio WEB tal como fue autorizado, únicamente para uso personal y comercial; (b) no copiar, ni distribuir ninguna parte del Sitio WEB sin la autorización previa y por escrito, del Proveedor; y (c) no alterar ni modificar ninguna parte del Sitio WEB.

Para poder tener acceso a los Servicios, el Usuario deberá crear una Cuenta, no pudiendo utilizar la Cuenta de otra persona sin su autorización. La Cuenta deberá ser generada con información fiel y completa, debiendo informar inmediatamente al Proveedor sobre cualquier falla de seguridad o uso no autorizado de la Cuenta. El Proveedor no será responsable de las pérdidas que se hayan causado por cualquier uso no autorizado de la Cuenta, el Usuario reconoce y acepta que él será el responsable de las pérdidas causadas al Proveedor o a otros debido a dicho uso no autorizado.

El Proveedor puede proporcionar enlaces a sitios WEB pertenecientes o gestionados por terceros, sin que por este hecho pueda entenderse, bajo ninguna circunstancia, que el Proveedor respalda el contenido, productos o servicios disponibles en dichos sitios web, y que no es responsable de su contenido o su seguridad. El enlace o conexión del Usuario a cualquier otro sitio web es de su exclusiva responsabilidad.

<span class="terms-title">Requisitos para los Usuarios y Consumidores</span>

Por la naturaleza del servicio, sólo los Usuarios podrán contratar el Servicio cuando reúna todos y cada uno de los siguientes requisitos:

• Ser mayor de edad conforme a las leyes de la República Mexicana, cuyo supuesto general es de 18 años.
• Contar con una identificación oficial vigente que acredite la fecha de su nacimiento (credencial para votar, pasaporte, cartilla del servicio militar, cédula profesional, etc., expedida por una institución gubernamental oficial mexicana).
• Dar lectura total y aceptar los presentes Términos y Condiciones. Se entenderán aceptados los presentes Términos y Condiciones por toda persona que ingrese a los Medios y/u obtenga una Cuenta, o sea beneficiario del Servicio, ya sea Usuario o Consumidor.
• Proporcionar a la Cuenta datos verídicos (nombre completo incluyendo apellidos, fecha de nacimiento, domicilio, dirección de correo electrónico y teléfono(s) de contacto), conforme le sean requeridos en los Medios. Igualmente deberá proporcionar cualesquiera otros datos que le sean requeridos por El Proveedor.
• Contar con un método de pago válido, mismo que será procesado por el Proveedor seleccionado ya sea PayPal, OpenPay o Stripe, ya contratado; o en su debido caso, legitimidad de la moneda mexicana pagada en efectivo al repartidor.

<span class="terms-title">Servicio</span>

El Servicio Follow Wears es un Servicio de venta y de entrega a domicilio de vestimenta en lo general (en lo sucesivo el(los) “Producto(s)”) (ver productos participantes en el Sitio WEB).

El tiempo de entrega de tu pedido es 3 semanas a partir de tu día de pago. En temporadas de alta demanda o situaciones fuera del curso normal de operación, la entrega podrá tardar hasta 45 días hábiles y en estos casos, se notificará en el checkout antes de realizar tu pago y/o en caso de ser necesario, se notificará al Usuario a través de la cuenta de correo electrónico registrada en su Cuenta. Además, en zonas restringidas o con otros casos que refieran restricciones de envíos o producción el servicio deberá de ser suspendido hasta que termine dicha restricción. El Servicio es exclusivo en los Estados y áreas de cobertura de Follow Wears.

Los horarios de Servicio son establecidos por localidad de acuerdo con las legislaciones locales, y los mismos pueden cambiar sin previo aviso.

El catálogo de Productos, promociones, precios y vigencias puede variar sin previo aviso en caso de así decidirlo por cualquier razón el Proveedor, por eventualidad y según la localidad.

Los repartidores del Servicio realizan la entrega a “pie de puerta” dependiendo de la localidad, por lo que no están obligados a colocar el producto al interior del inmueble.

Los repartidores del Servicio realizarán hasta 2 intentos para realizar la entrega a “pie de puerta”. En caso de que no haya un receptor disponible en ninguno de los 2 intentos, la orden será cancelada y se reembolsará solamente el 50% del importe pagado por dicha orden.

En el momento de la entrega del Servicio, los repartidores podrán solicitar:

• Una identificación oficial al receptor y la realización de una fotografía para verificar su identidad.
• Firma del receptor

En el caso de entregas donde el usuario del Servicio decida confirmar su entrega por medio de código QR, deberá mostrarlo al repartidor y esperar a que este sea escaneado para confirmar la entrega.

<span class="terms-title">Cobertura de servicio</span>

<span class="bold">México:</span>

Todo México.

<span class="terms-title">Del procesamiento de pagos</span>

El Proveedor se apoya, para la prestación del Servicio, de un procesador de pagos de tarjetas de débito y crédito a efecto de facilitar el cobro de los Productos. Dicho tercero es responsable de la información personal de carácter financiero que él solicita, por lo que por medio de estos Términos y Condiciones, los Usuarios y Consumidores liberan de toda responsabilidad relacionada con el procesamiento de pagos al Proveedor.

Las opciones de pago disponible son las siguientes:

• Tarjeta de débito o crédito – Procesado por Stripe (cuando aplique)
• Pago en efectivo contra entrega de producto – Procesado por agencia logística interna (cuando aplique)
• PayPal – Procesado por PayPal (cuando aplique)
• Pago en OXXO – Procesado por Stripe (cuando aplique)

Follow Wears no se hace responsable por la negación o inhabilidad de cobro en uno o más puntos físicos afiliados a Stripe y se reserva el derecho de suspender temporal o definitivamente los Pagos en tienda de conveniencia en temporadas de alta demanda y/o situaciones fuera del curso normal del negocio.

Al proporcionar en la página la información de tu tarjeta de crédito o débito el procesador de pagos obtiene el consentimiento del Usuario para el tratamiento de los datos personales que le hubiere solicitado.

El Proveedor recibe los pagos que se recaban a través de dicho tercero sin tener acceso directo a su información financiera.

Motivos para la negativa del servicio

• La participación de cualquier menor de edad.
• Proporcionar o afirmar cualquier dato que no sea cierto o pueda ser probado por el Usuario.
• Porque el Usuario o Consumidor se niegue u omita la entrega de documentos solicitados por el Proveedor, como el documento de identificación oficial.
• Cuando el área de entrega sea catalogada como zona de alto riesgo por Follow Wears o cualquiera de sus aliados estratégicos esenciales para la entrega de el Producto..
• Cuando el Consumidor o usuario que recibe el Producto se encuentre en estado de ebriedad o bajo los efectos de alguna sustancia psicotrópica y ello sea evidente.
• Cuando el Consumidor que recibe el pedido no pueda comprobar su mayoría de edad mediante una identificación oficial o se detecte irregularidades tales como; falsificación ostensible del documento de identidad, suplantación de personas o alteración de información previamente proporcionada.
• Cuando exista alguna restricción para acceder al domicilio final del consumidor, señalando de manera enunciativa, más no limitativa, los casos de vecindades o residenciales.
• Alta demanda o situaciones fuera del curso normal de la operación.
• Disposición oficial.

<span class="terms-title">Propiedad intelectual</span>

El Usuario acepta y reconoce que los contenidos del Sitio WEB incluyendo de manera enunciativa más no limitativa, textos, marcas, avisos comerciales, denominaciones, fotografías, diseños, logotipos, imágenes, sonidos, vídeos, animaciones, grabaciones, programas de cómputo, así como otros signos distintivos y, en general, cualquier creación intelectual disponible en los Medios, están debidamente registrados y protegidos por las leyes de Propiedad Intelectual e Industrial aplicables al efecto. En virtud de lo anterior se les prohíbe al Usuario y a cualquier persona que acceda a los Medios, su uso sin haber obtenido la licencia o autorización que corresponda conforme a la ley de la materia.

<span class="terms-title">Otros</span>

Se entenderá que toda persona que, directa o indirectamente, solicite o reciba el Servicio, ha conocido, entendido y aceptado íntegramente sujetarse y regirse conforme a los presentes Términos y Condiciones, renunciando expresamente a su derecho a deducir, promover, reclamar o ejercer cualquier acción en contra del Proveedor relacionada con los supuestos en que su responsabilidad está limitada o excluida en virtud de estos Términos y Condiciones hasta el máximo permitido por la ley.

Este Servicio está sujeto a cambios ocasionados por virtud de las leyes federales, locales o de resolución por parte de autoridades administrativas o judiciales, sin previo aviso a los Usuarios o al público en general.

<span class="terms-title">Uso de "cookies"</span>

El Sitio WEB podrá utilizar archivos temporales (“cookies”) para facilitar el acceso al Sitio WEB. Asimismo, el navegador o dispositivo que se utilice podrá generar cookies que permitan la operación de otras. Puede desactivarlas y/o eliminarlas mediante las opciones de privacidad de su navegador de Internet; en el entendido que ello podría afectar el uso del Sitio WEB.

<span class="terms-title">Política de Garantías y Reembolsos</span>

Todos los Usuarios y/o Consumidores cuentan con un plazo máximo de 24 horas, a partir de haber recibido sus productos, para enviar las evidencias de la inconformidad descritas a continuación y hacer válida la garantía del producto:

• Número de orden
• Fotografías de las piezas recibidas
• Fotografía del empaque dañado
• Fotografía de la guía de rastreo

Dichas evidencias deberán ser enviadas al correo electrónico hola@followears.com

Una vez recibido el correo electrónico con toda la información mencionada, el Proveedor analizará la inconformidad de que se trate y, en un plazo no mayor a 3 (tres) días hábiles, notificará al Usuario y/o Consumidor la resolución del caso en particular y, en todo caso, si el reembolso o la garantía es procedente.

Cuando se presente un caso de Entrega no Reconocida, el Proveedor realizará una investigación directamente con la empresa de mensajería, misma que tomará entre 3 y 5 días hábiles. En este tiempo se analizará el caso y si la resolución es favorable para el cliente, el Proveedor enviará los productos debatidos.

Durante todo este proceso, el Proveedor podrá requerir más información por parte del Usuario o Consumidor, por lo cual los plazos de respuesta podrían duplicarse.

Para reembolsos, en caso de que proceda la inconformidad, el Proveedor realizará el reembolso conforme a los siguientes lineamientos:
• Se otorgará un reembolso parcial del monto pagado dependiendo de la situación (Ver apartado de cancelaciones para situaciones específicas)
• Si el pago se realizó con tarjeta de crédito o débito (Visa, Mastercard, Paypal, American Express), el plazo para la devolución dependerá de los intermediarios bancarios, conforme a sus tiempos y políticas.
• Si el pago se realizó en efectivo contra entrega o en tiendas de conveniencia, los reembolsos tendrán un tratamiento especial en común acuerdo entre el Proveedor y el Cliente.

<span class="terms-title">Devoluciones</span>
En caso de que el cliente se encuentre ausente y el paquete no pueda ser entregado en el domicilio después de una visita por parte de la paquetería, el mismo será devuelto al almacén. El costo de la devolución será cargado directamente al cliente y se descontará al realizar el reembolso del dinero (ver garantías y reembolsos).

El costo de la devolución es de $150.00 m.n.

El cliente podrá solicitar el reenvío del paquete, el monto pagado será reembolsado en un cupón para que él mismo pueda realizar de nuevo su pedido. A este monto se le descontará el costo del reenvío.

<span class="terms-title">Causales de exclusión de responsabilidad</span>

Cada Usuario es el único y absoluto responsable de todas las actividades que ocurran bajo su Cuenta. El Proveedor no tiene control sobre el uso de la Cuenta de un Usuario y este último renuncia expresamente a cualquier reclamo derivado de dicho uso.

El Proveedor no otorga garantía, ni asume responsabilidad alguna por ningún daño y/o perjuicio directo o indirecto relacionado con la precisión e integridad del contenido del Sitio WEB o de cualquier sitio enlazado al del Proveedor, en virtud de lo anterior el Proveedor no es responsable por (a) errores o imprecisiones en el contenido del Sitio WEB, (b) lesiones personales o daños a la propiedad de cualquier tipo que resulten del acceso o uso del Sitio WEB, (c) acceso no autorizado a los Servidores del Proveedor y a la información de cualquier tipo almacenada en los mismos, (d) interrupción o inhabilitación temporal o permanente del Sitio WEB, (e) errores en el software utilizado, virus, troyanos, gusanos, malware o similares, que hayan sido introducidos en el Sitio WEB por cualquier tercero; y (f) errores u omisiones en el contenido o por cualquier pérdida o daño de cualquier tipo que resulte del uso de cualquier contenido publicado, enviado por correo electrónico, transmitido o de alguna otra forma puesto a disposición del Usuario mediante el sitio WEB.

Asimismo, el Usuario acepta que el Proveedor no se hace responsable por ningún daño y/o perjuicio directo o indirecto proveniente de o en relación con (i) el rendimiento o navegación en los Medios; o (ii) sus enlaces a otros sitios web, incluso si el Usuario ha sido informado de la posibilidad de tales daños. El Usuario acepta que el Proveedor no se hace responsable de los daños y/o perjuicios derivados de la interrupción, suspensión o terminación de los Servicios cuando sea aplicable conforme a los presentes Términos y Condiciones o la legislación vigente aplicable.

El Usuario reconoce y acepta que, dadas las características del flujo de información en Internet, resulta materialmente imposible para el Proveedor controlar y garantizar la ausencia de virus u otros elementos similares que puedan producir alteraciones en su sistema informático o en los documentos electrónicos almacenados en su sistema informático.

<span class="terms-title">Límite de Responsabilidad</span>

Al aceptar los presentes Términos y Condiciones el Usuario acepta que en ningún caso la responsabilidad total del Proveedor frente el Usuario, en caso de que la misma sea determinada por una autoridad judicial o administrativa competente, por cualquier clase de daños o pérdidas, podrá exceder los montos efectivamente pagados o pagaderos por el Usuario al Proveedor correspondientes a la orden del Servicio de la cual derive el reclamo.

<span class="terms-title">Jurisdicción aplicable</span>

Cualquier controversia suscitada por el Servicio y sus complementarias, se regirán por los presentes Términos y Condiciones, y por las leyes vigentes en los Estados Unidos Mexicanos, sujetándose a la jurisdicción y competencia de los Tribunales de la Ciudad de México, renunciando a la competencia de los tribunales que por beneficio de su domicilio presente o futuro pudiera corresponderles a las partes.

<span class="bold">Actualizado al 30 de marzo de 2021</span>
        </pre>    
</div>