import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument, DocumentReference } from '@angular/fire/firestore';
import firebase from 'firebase/app';
import { first, map } from 'rxjs/operators';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isLoggedIn = false

  constructor(private fireStore: AngularFirestore, public fireAuth: AngularFireAuth, public userService: UserService) { }

  async getCurrentUser() {
    return this.fireAuth.authState.pipe(first()).toPromise();
  }

  //Investigar cómo sacar rol de usuario
  isAdmin() {

  }

  signin(email: string, password: string) {
    this.fireAuth.signInWithEmailAndPassword(email, password)
      .then(res => {
        this.isLoggedIn = true
        localStorage.setItem('user', JSON.stringify(res.user))
      })
  }

  async googleSignIn() {
    const provider = new firebase.auth.GoogleAuthProvider()
    const credential = await this.fireAuth.signInWithPopup(provider)
    return (await this.fireStore.collection('users').doc(credential.user.uid).ref.get()).exists
  }

  async googleSignUp() {
    const provider = new firebase.auth.GoogleAuthProvider()
    const credential = await this.fireAuth.signInWithPopup(provider)
    return await this.updateUserData(credential.user)
  }

  async facebookSignIn() {
    const provider = new firebase.auth.FacebookAuthProvider()
    const credential = await this.fireAuth.signInWithPopup(provider)
    return (await this.fireStore.collection('users').doc(credential.user.uid).ref.get()).exists
  }

  async facebookSignUp() {
    const provider = new firebase.auth.FacebookAuthProvider()
    const credential = await this.fireAuth.signInWithPopup(provider)
    return this.updateUserData(credential.user)
  }

  private async updateUserData(user) {
  var document = this.fireStore.collection('users').doc(user.uid);
  if (!(await document.ref.get()).exists) {
    var userRef: AngularFirestoreDocument<any> = this.fireStore.doc(`users/${user.uid}`)
    const data = {
      email: user.email,
      role: "user",
      cards: []
    }
    await userRef.set(data, { merge: true })
    return true
  } else {
    return false;
  }

}

}
