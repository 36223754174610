import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CouponsService } from 'src/app/services/coupons.service';

@Component({
  selector: 'coupons',
  templateUrl: './admin-coupons.component.html',
  styleUrls: ['./admin-coupons.component.scss']
})
export class AdminCouponsComponent implements OnInit {

  couponInfo = new FormGroup({
    code: new FormControl('',Validators.required),
    discount: new FormControl(0, [Validators.required,Validators.min(1)])
  })

  coupons

  constructor(
    public fireAuth : AngularFireAuth,
    public router: Router,
    public couponsService: CouponsService) { }

  ngOnInit(): void {
    this.couponsService.getCoupons().subscribe((res) => {
      this.coupons = res.sort(this.compare);
    })
  }

  compare(a,b){
    if(a.code.toLowerCase() > b.code.toLowerCase())return 1
    return -1
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode == 46) return true
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  createCoupon(){
    this.couponsService.addCoupon({code: this.couponInfo.controls.code.value,discount: this.couponInfo.controls.discount.value})
    this.couponInfo.reset()
  }

  removeCoupon(id){
    this.couponsService.removeCoupon(id)
  }

  onLogout(){
    this.fireAuth.signOut().then(() =>{
        this.router.navigate(['login'])
      }
    )
  }

}
