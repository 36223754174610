<nav class="navbar-container">
    <div class="logo-container" routerLink="/inicio">
        <img src="../../../assets/Img/LOGO_FOLLOW_LARGO.png" class="logo">
    </div>
    <div class="navbar-links">
        <a class="text-16 bold navbar-link" routerLink="/inicio" routerLinkActive="selected-link">Inicio</a>
        <a class="text-16 bold navbar-link" routerLink="/tienda" routerLinkActive="selected-link">Tienda</a>
        <a class="text-16 bold navbar-link" routerLink="/login" *ngIf="!isLoggedIn">Iniciar Sesión</a>
        <a class="text-16 bold navbar-link profile-link" routerLink="/micuenta" routerLinkActive="selected-link" *ngIf="isLoggedIn && currentUser"><img class="user-img" [src]="currentUser.image">{{currentUser.name}}</a>
        <a class="text-16 bold navbar-link" id="cart-icon">({{productNumber}})&nbsp;<i class="fas fa-shopping-bag"></i>
            <div class="shopping-cart">
                <div class="cart-products">
                    <div class="cart-product" *ngFor="let item of cart.items; let i = index">
                        <img class="cart-product-image" [src]=item.image>
                        <div class="cart-product-details">
                            <p class="cart-product-name text-16">{{item.name}}</p>
                            <p class="cart-product-price text-16 bold">${{item.price}} <span class="text-12">mxn</span></p>
                            <input type="number" min="1" placeholder="1" [value]=item.quantity class="cart-product-number" name="quantity" #quantity (keypress)="numberOnly($event)" (change)="updateCartAmount(i,quantity.value)">
                        </div>
                        <p class="cart-product-remove-btn" (click)="removeFromCart(i)"><i class="fas fa-times"></i></p>
                    </div>
                    <div class="fade-effect-container"></div>
                </div>
                <p class="bold cart-total">Total: <span style="float: right; color: #04FAFC;" class="cart-total-number bold">{{totalCost | currency}} <span class="text-20">mxn</span></span></p>
                <button routerLink="/pago" class="coupon-btn bold text-16">PROCEDER AL PAGO</button>
        </div></a>
    </div>
    <i class="fa fa-bars hamburguer-icon" (click)="toggleMenu()"></i>
    <div class="navbar-links-mobile" [class.shown]="showMenu">
        <a class="text-20 bold navbar-link-mobile" (click)="toggleMenu()" routerLink="/inicio" routerLinkActive="selected-link">Inicio</a>
        <a class="text-20 bold navbar-link-mobile" (click)="toggleMenu()" routerLink="/tienda" routerLinkActive="selected-link">Tienda</a>
        <a class="text-20 bold navbar-link-mobile" (click)="toggleMenu()" routerLink="/login" *ngIf="!isLoggedIn" routerLinkActive="selected-link">Iniciar Sesión</a>
        <a class="text-20 bold navbar-link-mobile" (click)="toggleMenu()" routerLink="/micuenta" routerLinkActive="selected-link" *ngIf="isLoggedIn && currentUser">{{currentUser.name}}</a>
        <a class="text-20 bold navbar-link-mobile" (click)="toggleMenu()" routerLink="/pago" routerLinkActive="selected-link">({{productNumber}})&nbsp;<i class="fas fa-shopping-bag"></i></a>   </div>
</nav>