import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  errorText
  user = {
    name: "",
    mail: "",
    password: ""
  }

  constructor(public fireAuth: AngularFireAuth, private router: Router, public authService: AuthService, public userService: UserService) { }

  ngOnInit(): void {
  }

  signup() {
    if (this.user.name != "") {
      this.fireAuth.createUserWithEmailAndPassword(this.user.mail, this.user.password).then(user => {
        var uid = user.user.uid
        this.userService.createUserRecord({ id: uid, name: this.user.name, email: this.user.mail}).then(() => {
          this.router.navigate(['/micuenta'])
        })
      }
      ).catch(err => {
        if (err.code == "auth/invalid-email" || err.code == "auth/wrong-password") {
          this.errorText = "El correo o la contraseña ingresados no son válidos."
        } else if (err.code == "auth/email-already-in-use") {
          this.errorText = "Este correo ya se encuentra en uso."
        } else if (err.code == "auth/weak-password") {
          this.errorText = "La contraseña debe contener un mínimo de 6 caracteres"
        }
        console.log(err)
      })
    } else {
      this.errorText = "Por favor ingresa un nombre."
    }
  }

  /*async SignupWithGoogle(){
      this.authService.googleSignUp().then((created:any) => {
        if(!created){
          alert('Ya existe una cuenta asociada a este correo')
        }else{
          this.router.navigate(['/profile'])
        }
      })
  }

  SignUpWithFacebook(){
      this.authService.facebookSignUp().then(created => {
        if(!created){
          alert('Ya existe una cuenta asociada a este correo')
        }else{
          this.router.navigate(['/profile'])
        }
      })
  }*/

}
