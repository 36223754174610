import { Component, OnInit, Input } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavigationStart, Router, RouterModule } from '@angular/router';
import { timestamp } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { ShoppingCartService } from 'src/app/services/shopping-cart.service'
import { UserService } from 'src/app/services/user.service';
import { ShoppingCart } from 'src/app/shared/shoppingCart';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  currentUser;
  isLoggedIn = false;
  productNumber = 0;
  totalCost = 0;
  cart: ShoppingCart;
  showMenu;

  constructor(public firebaseAuthService: AuthService, router: Router, public cartService: ShoppingCartService, public userService: UserService) {
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        this.hasUser()
      }
    });
  }

  ngOnInit(): void {
    this.cartService.getTokens().subscribe((res) => {
      this.productNumber = 0
      this.totalCost = 0
      this.cart = res;
      for (let i of res.items) {
        this.productNumber += i.quantity;
        this.totalCost += i.price * i.quantity;
      }
    })
    if (localStorage.getItem('cart')) {
      this.cartService.setTokens(JSON.parse(localStorage.getItem('cart')));
    } else {
      localStorage.setItem('cart', JSON.stringify({ userID: "", items: [] }))
      this.cartService.setTokens({ userID: "", items: [] });
    }
  }

  hasUser() {
    this.firebaseAuthService.getCurrentUser().then((res) => {
      if (res != undefined) {
        this.isLoggedIn = true;
        this.userService.getUserById(res.uid).subscribe((res) => {
          this.currentUser = res;
          if(res.image==null) this.currentUser.image = "assets/Img/fw-placeholder.png"
        })
      } else {
        this.isLoggedIn = false;
      }
    });
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode == 46) return true
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  removeFromCart(index) {
    this.cart.items.splice(index, 1);
    this.cartService.setTokens(this.cart);
    localStorage.setItem('cart', JSON.stringify(this.cart))
  }

  updateCartAmount(index, value) {
      this.cart.items[index].quantity = parseInt(value)
      this.cartService.setTokens(this.cart);
      localStorage.setItem('cart', JSON.stringify(this.cart))
  }

  toggleMenu(){
    this.showMenu = !this.showMenu;
  }
}
