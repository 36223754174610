<div id="first-section">
    <img src="../../../assets/Img/Landing-blue-poligon.png" id="first-vector">
    <div class="first-section-text">
        <p class="neon-text text-50 tu-perfil-text">TU PERFIL SOCIAL EN UN QR</p>
        <p class="text-18 justified landing-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer vulputate suspendisse lectus dignissim ornare. Risus lorem faucibus sem arcu.</p>
        <a class="buy-button text-20" routerLink="/tienda">COMPRAR AHORA</a>
    </div>
    <img src="../../../assets/Img/hoodie_back.png" class="hoodie-img">
    <img src="../../../assets/Img/pink_dots.png" class="pink-dots">
    <img src="../../../assets/Img/blue_dots.png" class="blue-dots">
    <img src="../../../assets/Img/landing-blue-big-triangle.png" class="blue-triangle-big">
</div>
<div class="second-section">
    <img src="../../../assets/Img/FW_QR.png" class="fw-qr">
    <img src="../../../assets/Img/IphoneX-1.png" class="iPhoneX">
    <div class="second-section-text">
        <p class="neon-text text-50">¿CÓMO FUNCIONA?</p>
        <p class="text-18 second-section-description justified">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer vulputate suspendisse lectus dignissim ornare. Risus lorem faucibus sem arcu.
            Laoreet leo id egestas amet vitae scelerisque.</p>
        <a class="buy-button text-20" routerLink="/tienda">OBTENLO AHORA</a>
    </div>
</div>
<div class="tidbits-section">
    <div class="tidbit">
        <img src="../../../assets/Img/tidbit1.png">
        <p class="text-18"><span class="bold">Aumenta tu visibilidad</span><br>Lleva tu marca a todas partes con nuestra línea de ropa urbana.</p>
    </div>
    <div class="tidbit">
        <img src="../../../assets/Img/tidbit2.png" style="width: 60px;">
        <p class="text-18"><span class="bold">Gana Followers</span><br>No pierdas la oportunidad de hacer crecer tu comunidad.</p>
    </div>
    <div class="tidbit">
        <img src="../../../assets/Img/tidbit3.png">
        <p class="text-18"><span class="bold">No más tarjetas de contácto</span><br>Lleva tu marca a todas partes con nuestra línea de ropa urbana.</p>
    </div>
    <div class="tidbit">
        <img src="../../../assets/Img/tidbit4.png">
        <p class="text-18"><span class="bold">Eres tu propia marca</span><br>Tú serás tu propia marca y podrás conectar en segundos con todo el mundo.</p>
    </div>
</div>
<div class="third-section">
    <p class="neon-text text-50">LÍNEAS DE PRODUCTO</p>
    <img src="../../../assets/Img/blue-dots-second.png" class="blue-dots-second">
    <div class="third-section-sudaderas" routerLink="/tienda">
        <p class="neon-text text-50">SUDADERAS</p>
    </div>
    <div class="third-section-bottoms" routerLink="/tienda">
        <p class="neon-text text-50">BOTTOMS</p>
    </div>
</div>
<div class="fourth-section">
    <img src="../../../assets/Img/pink-triangle-second.png" class="pink-triangle-second">
    <div class="profile">
        <img src="../../../assets/Img/first-profile.png">
        <p class="profile-title text-18">Título</p>
        <p class="profile-description text-18">Lorem ipsum dolor sit amet, consectetur.</p>
    </div>
    <div class="profile">
        <img src="../../../assets/Img/second-profile.png">
        <p class="profile-title text-18">Título</p>
        <p class="profile-description text-18">Lorem ipsum dolor sit amet, consectetur.</p>
    </div>
    <div class="profile">
        <img src="../../../assets/Img/third-profile.png">
        <p class="profile-title text-18">Título</p>
        <p class="profile-description text-18">Lorem ipsum dolor sit amet, consectetur.</p>
    </div>
    <div class="profile">
        <img src="../../../assets/Img/fourth-profile.png">
        <p class="profile-title text-18">Título</p>
        <p class="profile-description text-18">Lorem ipsum dolor sit amet, consectetur.</p>
    </div>
</div>
<div class="fifth-section">
    <p class="neon-text text-50 fifth-section-title">LLEVA FOLLOW WEARS A TU EMPRESA</p>
    <a class="buy-button-third text-20" routerLink="/mayoreo">OBTENLO AHORA</a>
</div>
<div class="footer">
    <img src="../../../assets/Img/fw-logo-short.png" class="logo-footer">
    <div>
        <a><img src="../../../assets/Img/fw-instagram.png" class="footer-social"></a>
        <a><img src="../../../assets/Img/fw-twitter.png" class="footer-social"></a>
        <a><img src="../../../assets/Img/fw-facebook.png" class="footer-social"></a>
    </div>
    <p class="text-20">Follow Wears ©. Todos los derechos reservados. 2020
        <a><span routerLink="/terminos">Terminos y Condiciones</span> | <span routerLink="/aviso">Aviso de privacidad</span></a></p>
</div>
