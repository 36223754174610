import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as firebase from 'firebase'
import { Upload } from '../shared/upload'

export interface network {
  website: string,
  profile: string,
  link: string,
  image: string
}

export interface user {
  id?: string,
  name: string,
  phone: string,
  email: string,
  role: string,
  image: string,
  imageName: string,
  networks: network[],
  paymentMethods: any[]
}

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private users:Observable<user[]>;
  private user;
  private userList;
  private usersCollection: AngularFirestoreCollection<user>;

  constructor(private firestore: AngularFirestore) {
    this.usersCollection = this.firestore.collection<user>('users');
    this.userList = this.usersCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
   }

  createUserRecord(data) {
    return new Promise<any>((resolve, reject) =>{
        this.firestore
            .collection("users")
            .doc(data.id)
            .set({
              name: data.name,
              email: data.email,
              role: 'user',
              phone: 0,
              networks: []
            }).then(res => {resolve(res)}, err => reject(err));
    });
  }

  createUserRecordAdmin(data) {
    return new Promise<any>((resolve, reject) =>{
        this.firestore
            .collection("users")
            .doc(data.id)
            .set({
              name: data.name,
              email: data.email,
              role: 'mayoreo',
              phone: 0,
              networks: []
            }).then(res => {resolve(res)}, err => reject(err));
    });
  }

  getUserRecord(id) {
    return new Promise<any>((resolve, reject) =>{
        this.usersCollection
            .doc(id)
            .ref
            .get()
            .then(res => {resolve(res.data())}, err => reject(err));
    });
  }

  getUserById(id:string): Observable<user>{
    return this.usersCollection.doc<user>(id).valueChanges().pipe(
    map(user => {
      user.id = id;
      return user
    })
  )}

  saveUserCard(cards, id){
    return this.usersCollection.doc(id).update(cards);
  }

  saveUserChanges(user, image, id){
    if(image){
      let storageRef = firebase.default.storage().ref()
      let uploadTask = storageRef.child(`uploads/${user.name+image.file.name}`).put(image.file);
      uploadTask.on(firebase.default.storage.TaskEvent.STATE_CHANGED,
        (snapshot) =>{
          image.progress = (snapshot.bytesTransferred/snapshot.totalBytes)*100
        },
        (error) => {
          console.log(error)
        },
        ()=>{
           uploadTask.snapshot.ref.getDownloadURL().then(url =>{
            image.url = url
            image.name = image.file.name
            return this.usersCollection.doc(id).update({name:user.name,phone:user.phone,email:user.email,image:url,imageName: user.name+image.file.name});
        })
      })
    }

    return this.usersCollection.doc(id).update({name:user.name,phone:user.phone,email:user.email});
  }

  deleteUserImage(url){
    let storageRef = firebase.default.storage().ref()
    storageRef.child(`uploads/${url}`).delete();
  }

  getUserWEmail(user) {
    let usersCollection: AngularFirestoreCollection<user> = this.firestore.collection("users", (ref) => ref.where("email", "==", user));
    this.userList = usersCollection.snapshotChanges().pipe(map((actions) => {
      return actions.map((a) => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        return { id, ...data };
      });
    }));
    return this.userList;
  }

  updateNetworks(id,networkList){
    this.usersCollection.doc(id).update({
      networks: networkList
    })
  }

  updatePaymentMethods(id,paymentMethodsList){
    this.usersCollection.doc(id).update({
      paymentMethods: paymentMethodsList
    })
  }

  getUsers(){
    return this.userList;
  }

}
