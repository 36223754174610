<div class="admin-wrapper">
    <div class="admin-sidebar">
        <div class="admin-logo">
            <img src="../../../assets/Img/fw-logo-short.png">
        </div>
        <div class="admin-tabs">
            <div class="admin-tab" routerLink="/admin/pedidos">
                <p><i class="fas fa-truck"></i> Pedidos</p>
            </div>
            <div class="admin-tab" routerLink="/admin/categorias">
                <p><i class="fas fa-tags"></i> Categorias</p>
            </div>
            <div class="admin-tab" routerLink="/admin/productos">
                <p><i class="fas fa-boxes"></i> Productos</p>
            </div>
            <div class="admin-tab selected-tab" routerLink="/admin/cupones">
                <p><i class="fas fa-ticket-alt"></i> Cupones</p>
            </div>
            <div class="admin-tab" routerLink="/admin/usuarios">
                <p><i class="fas fa-user-friends"></i> Usuarios</p>
            </div>
        </div>
        <div class="log-out-tab" (click)="onLogout()">
            <p><i class="fas fa-sign-out-alt"></i> Cerrar sesión</p>
        </div>
    </div>
    <div class="dashboard-content">
        <div class="coupons-header">
            <p class="bold admin-title">Cupones</p>
        </div>
        <div class="categories-body">
            <div class="form-fields" [formGroup]="couponInfo">
                <p class="new-coupon-title bold">NUEVO CUPÓN</p>
                <p>Llena todos los campos para agregar un cupón</p>
                <label for="coupon-code" class="text-12 bold">Código del cupón</label>
                <input formControlName="code" [class.error-outline]="couponInfo.get('code').invalid && couponInfo.get('code').touched" id="coupon-code" name="category-name" placeholder="Ingresa un código">
                <p class="error-label" *ngIf="couponInfo.get('code').invalid && couponInfo.get('code').touched">Por favor ingresa un código</p>
                <label for="coupon-discount" class="text-12 bold">Descuento</label>
                <input formControlName="discount" [class.error-outline]="couponInfo.get('discount').invalid && couponInfo.get('discount').touched" id="coupon-discount" name="category-discount" (keypress)="numberOnly($event)" placeholder="Ingresa un número">
                <p class="error-label" *ngIf="couponInfo.get('discount').invalid && couponInfo.get('discount').touched">Por favor ingresa un número</p>
                <button class="primary-btn" [class.disabled-btn]="couponInfo.invalid" (click)="createCoupon()">AGREGAR</button>
            </div>
            <div class="coupons-table">
                <div class="coupons-table-header">
                    <p class="bold">Código</p>
                    <p class="bold">Descuento</p>
                </div>
                <div class="coupons-body">
                    <div class="coupon" *ngFor="let coupon of coupons;">
                        <p class="bold">{{coupon.code}}</p>
                        <p class="bold">{{coupon.discount | currency}}</p>
                        <i class="fas fa-minus-circle" style="color: red;" (click)="removeCoupon(coupon.id)"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>