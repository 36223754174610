<div class="product-wrapper">
    <div class="product-container">
        <div class="product-photos">
            <img [src]=product.images[i] [ngClass]="{'active-image':selectedImage==image}" class="product-photo" *ngFor="let image of product.images; let i = index" (click)="selectImage(product.images[i])">
        </div>
        <div class="product-big-photo-container">
            <img [src]=selectedImage class="product-big-photo">
        </div>
        <div class="product-details">
            <p class="product-title text-50">{{product.name}}</p>
            <div class="product-pricing">
                <p class="product-detail-price text-50 neon-text-light">$&nbsp;{{product.price}}<span class="text-20">&nbsp;MXN</span></p>
                <p class="product-detail-add-cart text-16" [class.purchase-disabled-btn]="selectedColor == '' || selectedSize == ''" (click)="addToCart(product)">AGREGAR AL CARRITO</p>
            </div>
            <div class="product-detail-colors-container">
                <p class="text-12">COLOR:</p>
                <div class="product-detail-colors">
                    <div class="product-detail-color" [style]='"background-color:"+color.code' *ngFor="let color of product.colors; let i = index" (click)="selectColor(color.name)" [ngClass]="{'active-color':selectedColor==color.name}"></div>
                </div>
            </div>
            <div class="product-detail-sizes-container">
                <p class="text-12">TALLA:</p>
                <div class="product-detail-sizes">
                    <div class="product-detail-size" *ngFor="let size of product.sizes; let i = index" [ngClass]="{'active-size':selectedSize==size}" (click)="selectSize(size)">{{size | uppercase}}</div>
                </div>
                <p class="size-guia text12"><i class="fas fa-tshirt"></i> Ver guía de tallas</p>
            </div>
            <p class="text-16 product-detail-description">{{product.description}}</p>
        </div>
    </div>
</div>

